import { useFormik } from "formik";
import * as Yup from "yup";
import { getLoaderOptions, getToastOptions, handleError } from "../lib";
import Auth from "../classes/Auth";
import { useIonLoading, useIonToast } from "@ionic/react";

const useLogin = () => {
  const [presentLoader, dismissLoader] = useIonLoading();
  const [present] = useIonToast();
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async ({ email, password }, { setSubmitting }) => {
      presentLoader(getLoaderOptions("Please wait..."));
      try {
        //   signin user
        const auth = new Auth();
        await auth.signIn(email, password);
        dismissLoader();
      } catch (error: any) {
        dismissLoader();
        present(getToastOptions(handleError(error)));
      }
    },
  });

  return {
    formik,
  };
};

export default useLogin;
