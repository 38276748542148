import { CollectionLimits, CollectionNames } from "../enums";
import {
  db,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  timestamp,
  getDoc,
  doc,
} from "../firebase.config";
import { ILesson } from "../types";

const lessonsService = {
  fetchLessons: async (language: string, level: number) => {
    const lessonsRef = collection(db, CollectionNames.LESSONS);
    const q = query(
      lessonsRef,
      where("accessLevel", "<=", level),
      where("language", "==", language),
      // orderBy("createdAt", "desc"),
    );
    const snapshots = await getDocs(q);

    const lessons: ILesson[] = [];

    snapshots.forEach((doc) => {
      const lesson = {
        id: doc.id,
        ...doc.data(),
      };
      lessons.push(lesson as ILesson);
    });

    return lessons;
  },

  getLessonById: async (id: string) => {
    const snapshot = await getDoc(doc(db, CollectionNames.LESSONS, id));
    if (!snapshot.exists()) throw new Error("Cannot find lesson");
    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as ILesson;
  },
};

export default lessonsService;
