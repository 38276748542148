import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../index";
import lessonsService from "../../services/lessons.service";
import { ILesson } from "../../types";
import { handleError } from "../../lib";

export const fetchLessonsAction = createAsyncThunk<ILesson[], string>(
  "lessons/fetchLessonsAction",
  async (language, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;

      if (state.auth.user?.subscription.accessLevel) {
        const lessons = await lessonsService.fetchLessons(
          language,
          state.auth.user?.subscription.accessLevel,
        );

        return lessons;
      }

      return [];
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(handleError(error));
    }
  },
);
