import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ITransactions } from "../../types";

// Define a type for the slice state
export interface IInitialState {
  lastVisible: any;
  more: boolean;
  loaded: boolean;
  transactions: ITransactions[];
}

// Define the initial state using that type
const initialState: IInitialState = {
  transactions: [],
  lastVisible: null,
  more: false,
  loaded: false,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    loadTransactions: (state, action) => {
      state.lastVisible = action.payload.lastVisible;
      state.loaded = true;
      state.more = action.payload.more;
      state.transactions = [
        ...state.transactions,
        ...action.payload.transactions,
      ];
    },
  },
});

export const { loadTransactions } = transactionsSlice.actions;
export const selectTransactions = (state: RootState) => state.transactions;

const transactionsReducer = transactionsSlice.reducer;
export default transactionsReducer;
