import {
  auth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "../firebase.config";
import { IAuth } from "../types/auth";
import User from "./User";

class Auth implements IAuth {
  private auth = auth;

  resetPassword: (email: string) => Promise<void> = async (email) => {
    await sendPasswordResetEmail(this.auth, email);
  };

  signOut: () => Promise<void> = async () => {
    await this.auth.signOut();
  };

  public register: (email: string, password: string) => Promise<string> =
    async (email, password) => {
      if (!email || !password) throw new Error("Missing Argument");

      const { user } = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password,
      );
      return user.uid;
    };

  public signIn: (email: string, password: string) => Promise<void> = async (
    email,
    password,
  ) => {
    if (!email || !password) throw new Error("Missing Argument");
    await signInWithEmailAndPassword(this.auth, email, password);
  };

  public authenticateUser: (cb: any) => void = (cb) => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        cb(null);
      } else {
        // get Profile and watch user;
        const newUser = new User(user.uid);
        try {
          const profile = await newUser.getProfile();
          cb(profile);
        } catch (error) {
          cb(null, error);
        }
      }
    });
  };
}

export default Auth;
