import { CollectionLimits, CollectionNames } from "../enums";
import {
  db,
  collection,
  query,
  limit,
  orderBy,
  getDocs,
  where,
} from "../firebase.config";
import { formatDate } from "../lib";

export const fetchRecommendedService = async () => {
  const lessonsRef = collection(db, CollectionNames.LESSONS);
  const q = query(
    lessonsRef,
    where("accessLevel", "<=", 3),

    limit(CollectionLimits.RECOMMENDED),
  );
  const snapshots = await getDocs(q);

  const recommended: any = [];

  snapshots.forEach((doc) => {
    const lesson = {
      id: doc.id,
      ...doc.data(),
    };

    formatDate(lesson);
    recommended.push(lesson);
  });

  return recommended;
};
