import { Timestamp } from "@firebase/firestore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "..";

export interface IUser {
  dob: string;
  email: string;
  fullname: string;
  nationality: string;
  gender: string;
  id: string;
  createdAt: Timestamp;
  profilePicture?: {
    url: string;
    path: string;
  };
  subscription: {
    accessLevel: number;
    features: string[];
    createdAt?: Timestamp;
    expiresIn?: Timestamp;
    description: string;
    amount: number;
    duration: string;
  };
  role: string;
}

export interface ITutor {
  dob: string;
  email: string;
  fullname: string;
  nationality: string;
  gender: string;
  phone: string;
  profficientLanguage: string;
  otherLanguages: string;
  id: string;
  createdAt: Timestamp;
  profilePicture?: {
    url: string;
    path: string;
  };
  verificationId?: {
    url: string;
    path: string;
  };
  certification?: {
    url: string;
    path: string;
  };
  approved: boolean;
}

// Define a type for the slice state
interface AuthState {
  loaded: boolean;
  user: IUser | null;
  tutor: ITutor | null;
}

// Define the initial state using that type
const initialState: AuthState = {
  loaded: false,
  user: null,
  tutor: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.loaded = true;
      state.user = action.payload;
    },

    setTutor: (state, action) => {
      state.tutor = action.payload;
    },
  },
});

export const { setAuth, setTutor } = authSlice.actions;
export const selectUser = (state: RootState) => state.auth;

const authReducer = authSlice.reducer;
export default authReducer;
