import { auth, storage, getDownloadURL, ref } from "../firebase.config";

const authService = {
  getAuthToken: async (): Promise<string | undefined> => {
    const token = await auth.currentUser?.getIdToken(true);
    return token;
  },

  getDownloadUrls: async (urls: string[]) => {
    const promise = urls.map((url) => getDownloadURL(ref(storage, url)));
    const res = await Promise.all(promise);
    return res;
  },
};

export default authService;
