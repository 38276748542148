import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
} from "@ionic/react";
import {
  closeCircle,
  closeCircleOutline,
} from "ionicons/icons";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

interface Props {
    color: string;
    title: string;
    subtitle: string;
    message: string;
    noBackground?: boolean;
    noCloseBtn?: boolean;
    noPadding?: boolean;
}

const AlertCard: React.FC<Props> = ({color, title, subtitle, message, noBackground, noCloseBtn, noPadding}) => {
  const alertCardRef = useRef<HTMLIonCardElement>(null);
  const location = useLocation()

  useEffect(() => {
    alertCardRef.current?.classList.add("animate__bounceIn");
    alertCardRef.current?.classList.add("show");
    alertCardRef.current?.classList.remove("animate__bounceOut");
    alertCardRef.current?.classList.remove("hide");
    return () => {
      alertCardRef.current?.classList.remove("animate__bounceOut");
      alertCardRef.current?.classList.remove("hide");
    };
  }, [, location]);

  const handleClose = () => {
    alertCardRef.current?.classList.remove("animate__bounceIn");
    alertCardRef.current?.classList.remove("show");
    alertCardRef.current?.classList.add("animate__bounceOut");
    setTimeout(() => {alertCardRef.current?.classList.add("hide");}, 1000)
  }

  return (
    
    <div className={`${!noPadding && "ion-padding"}`}>
      <IonCard
        className={`empty-state-card animate__animated ${noBackground && "no-bg"}`}
        color={color}
        ref={alertCardRef}
      >
        <IonCardContent className="ion-text-start">
          {!noCloseBtn && (
          <IonButton
            onClick={handleClose}
            fill="clear"
            className="absolute top-0 right-0 z-10"
          >
            <IonIcon icon={closeCircle} color="light"/>
          </IonButton>)}

          <h3 className="ion-text-capitalize">
            {title}
            <br />
            <strong className="">{subtitle}</strong>
          </h3>
          <p>
            {message}
          </p>

          <img src={`assets/img/lady.svg`} alt="tutor lady illustration" />
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default AlertCard
