import { useMemo } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { selectLessons } from "../store/slices/lessonsSlice";
import { useAppSelector } from "./useDispatchSelector";
import lessonsService from "../services/lessons.service";

const useLesson = () => {
  const { lessonID } = useParams<{ lessonID: string }>();
  const { lessons } = useAppSelector(selectLessons);
  const lesson = useMemo(
    () => lessons.find((l) => l.id === lessonID),
    [lessonID, lessons],
  );

  const { status, error, data } = useQuery({
    queryKey: ["lessons", lessonID],
    queryFn: async () => await lessonsService.getLessonById(lessonID),
    enabled: lesson && lessonID ? false : true,
  });

  return {
    lessonID,
    status,
    error,
    data,
    lesson,
  };
};

export default useLesson;
