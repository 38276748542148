import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";
import { Link, useLocation } from "react-router-dom";
import {
  gameControllerOutline,
  gameControllerSharp,
  globeOutline,
  globeSharp,
  cardOutline,
  cardSharp,
  personOutline,
  personSharp,
  chevronBack,
  chevronForward,
} from "ionicons/icons";
import "../styles/LeftDrawer.css";
import useGeneral from "../hooks/useGeneral";
// import { Wave } from "./UIComponents";
import { useState } from "react";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Explore",
    url: "/explore",
    iosIcon: globeOutline,
    mdIcon: globeSharp,
  },
  {
    title: "Games",
    url: "/games",
    iosIcon: gameControllerOutline,
    mdIcon: gameControllerSharp,
  },
  {
    title: "Pricing & Plans",
    url: "/subscriptions",
    iosIcon: cardOutline,
    mdIcon: cardSharp,
  },
  {
    title: "Profile",
    url: "/profile",
    iosIcon: personOutline,
    mdIcon: personSharp,
  },
];

const LeftDrawer: React.FC = () => {
  const location = useLocation();
  const {
    auth: { user },
  } = useGeneral();
  const [shrink, setShrink] = useState(true);

  return user ? (
    <IonMenu
      contentId="main"
      type="overlay"
      className={`${shrink ? "shrink" : "dashboard-menu"}`}
    >
      <IonContent className="menu">
        <div className={`${shrink ? "flex-col" : "flex-row"} " flex items-center justify-space-between w-full"`}>
          <Link
            to="/profile"
            className={`${shrink ? "shrink" : ""} profile-image block`}
          >
            <img
              src={user?.profilePicture?.url || "assets/icon/icon.png"}
              alt="profile"
              ref={(e) => {
                e?.addEventListener("error", (_) =>
                  e.setAttribute("src", "assets/icon/icon.png")
                );
              }}
            />
          </Link>

          <IonButton
            className={`${shrink ? "m-6" : "float-right"}`}
            expand="block"
            fill="solid"
            onClick={() => setShrink(!shrink)}
          >
            <IonIcon icon={shrink ? chevronForward : chevronBack} />
          </IonButton>
        </div>
        <IonList id="menu-list" inset className="m-0 mt-16 mb-16">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "" : "selected"
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                    className={`${shrink && "shrink"}`}
                  />
                  {!shrink && <IonLabel>{appPage.title}</IonLabel>}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        {/* {!shrink && <Wave />} */}
      </IonContent>
    </IonMenu>
  ) : (
    <IonMenu contentId="main" type="overlay">
      <IonContent className="onboarding">
        <img
          src="assets/icon/icon-white.svg"
          alt=""
          className="h-50 absolute right-16 top-16"
        />
        <IonSlides
          pager={true}
          options={{
            speed: 400,
            slidesPerView: 1,
            autoplay: { delay: 5000 },
          }}
          style={{ height: "100%" }}
        >
          {[
            {
              img: "learning.svg",
              title: "Standard E-learning",
              body: "Our platform provides world standard e-learning that allows your to learn anywhere, anytime",
            },
            {
              img: "cost.svg",
              title: "Affordable Cost",
              body: "Our platform provides world standard e-learning that allows your to learn anywhere, anytime",
            },
          ].map((v, i) => (
            <IonSlide key={i}>
              <div className="flex flex-col justify-center">
                <img src={`assets/img/${v.img}`} alt="" className="h-300" />
                <IonText className="ion-margin-top" color="light">
                  <h3>{v.title}</h3>
                  <p>{v.body}</p>
                </IonText>
              </div>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonMenu>
  );
};

export default LeftDrawer;
