import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import {
  eyeOffOutline,
  eyeOutline,
  golfOutline,
  lockClosedOutline,
  mailOutline,
  maleFemaleOutline,
  personOutline,
} from "ionicons/icons";
// import '../styles/Signup.css';
import { useState } from "react";
import useSignup from "../hooks/useSignup";
import countries from "../countries.json";

const Signup: React.FC = () => {
  const { formik } = useSignup();
  const [pwdVisible, setPwdVisible] = useState(false);
  return (
    <IonRow className="h-full">
      <IonCol
        size="12"
        sizeLg="6"
        offsetLg="3"
        className="mt-auto mb-auto"
      ></IonCol>
      <IonCol size="12" sizeLg="6" offsetLg="3">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <h1>Create Account</h1>
            </IonCardTitle>
            <p>Let's get you started, fill in the fields below</p>
          </IonCardHeader>
          <IonCardContent>
            <form className="mt-40  mb-auto" onSubmit={formik.handleSubmit}>
              <IonRow>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Full name</IonLabel>
                    <IonIcon icon={personOutline} slot="start" />
                    <IonInput
                      type="text"
                      name="fullname"
                      placeholder="Enter fullname here"
                      value={formik.values.fullname}
                      onIonChange={formik.handleChange}
                    />
                  </IonItem>

                  {formik.touched.fullname && formik.errors.fullname ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                        {formik.errors.fullname}
                      </p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonIcon icon={mailOutline} slot="start" />
                    <IonInput
                      type="email"
                      name="email"
                      placeholder="Enter email address here"
                      value={formik.values.email}
                      onIonChange={formik.handleChange}
                    />
                  </IonItem>
                  {formik.touched.email && formik.errors.email ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">{formik.errors.email}</p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Gender</IonLabel>
                    <IonIcon icon={maleFemaleOutline} slot="start" />
                    <IonSelect
                      name="gender"
                      placeholder="Select Gender"
                      onIonChange={formik.handleChange}
                      value={formik.values.gender}
                    >
                      {["Male", "Female", "Others"].map((v, i) => (
                        <IonSelectOption value={v} key={i}>
                          {v}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  {formik.touched.gender && formik.errors.gender ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                        {formik.errors.gender}
                      </p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Date of Birth</IonLabel>
                    <IonIcon icon={mailOutline} slot="start" />
                    <IonInput
                      type="date"
                      name="dob"
                      placeholder="Enter date of birth here"
                      value={formik.values.dob}
                      onIonChange={formik.handleChange}
                    />
                  </IonItem>
                  {formik.touched.dob && formik.errors.dob ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">{formik.errors.dob}</p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Nationality</IonLabel>
                    <IonIcon icon={golfOutline} slot="start" />
                    <IonSelect
                      name="nationality"
                      placeholder="Select Country"
                      value={formik.values.nationality}
                      onIonChange={formik.handleChange}
                    >
                      {countries.map((v, i) => (
                        <IonSelectOption value={v} key={i}>
                          {v}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  {formik.touched.nationality && formik.errors.nationality ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                        {formik.errors.nationality}
                      </p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonItem color="clear" >
                    <IonLabel position="stacked">Password</IonLabel>
                    <IonIcon icon={lockClosedOutline} slot="start" />
                    <IonInput
                      type={pwdVisible ? "text" : "password"}
                      name="password"
                      placeholder="Enter password here"
                      value={formik.values.password}
                      onIonChange={formik.handleChange}
                    />
                    <IonIcon
                      icon={pwdVisible ? eyeOffOutline : eyeOutline}
                      slot="end"
                      onClick={() => setPwdVisible(!pwdVisible)}
                    />
                  </IonItem>
                  {formik.touched.password && formik.errors.password ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                        {formik.errors.password}
                      </p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonButton
                    expand="block"
                    color="success"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="ion-no-margin"
                  >
                    Sign up
                  </IonButton>
                </IonCol>
                <IonCol size="12">
                  <IonButton
                    routerLink="/authentication/login"
                    fill="clear"
                    color="medium"
                    expand="block"
                    mode="md"
                  >
                    Already have an account? Sign In
                  </IonButton>
                </IonCol>
              </IonRow>
            </form>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default Signup;
