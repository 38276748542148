import {
  IonButton,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonFooter,
  IonTextarea,
} from "@ionic/react";
import { closeOutline, send } from "ionicons/icons";
import React, { useState } from "react";
import { Socket } from "socket.io-client";
import useGeneral from "../hooks/useGeneral";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
  messages: Array<{ message: string; from: string; me?: boolean }>;
  socket: Socket | null;
  roomId: string;
  setMessages: any;
  setNewMessageCount: any;
}

const Chat: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  messages,
  socket,
  roomId,
  setMessages,
  setNewMessageCount,
}) => {
  const { auth } = useGeneral();
  const [message, setMessage] = useState<string>("");
  const sendMessage = () => {
    if (!message) return;
    const data = {
      from: auth?.user?.fullname,
      message,
      roomId,
    };
    setMessages((prevState: any) => [...prevState, { ...data, me: true }]);
    socket?.emit("message", data);
    setMessage("");
  };
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Chat</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setIsOpen(false);
                setNewMessageCount(0);
              }}
            >
              <IonIcon icon={closeOutline} color="secondary" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        {messages.map((m, i) => (
          <div className={`${m.me ? "ion-text-right" : ""}`} key={`chat-${i}`}>
            {!m.me ? <h6>{m.from}</h6> : null}
            <p>{m.message}</p>
          </div>
        ))}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTextarea
            placeholder="Enter message here..."
            value={message}
            onIonChange={(e) => setMessage(e.detail.value!)}
          ></IonTextarea>
          <IonButtons slot="end">
            <IonButton onClick={sendMessage}>
              <IonIcon icon={send} color="secondary" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default Chat;
