import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBadge,
  IonList,
} from "@ionic/react";
import {
  closeCircleOutline,
  notificationsOutline,
  notificationsSharp,
} from "ionicons/icons";
import { useState } from "react";

const Notifications: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [msgRead, setMsgRead] = useState(0);
  return (
    <>
      <IonButton onClick={() => setShowModal(true)}>
        <IonIcon ios={notificationsOutline} md={notificationsSharp} />
        <IonBadge color="secondary">2</IonBadge>
      </IonButton>
      <IonModal
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Notifications</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonList lines="inset">
            {[
              {
                id: 1,
                title: "Weekend live streaming!",
                body: "Join us on Saturday night live at 7:00pm GMT for a quick pod cast session with Jammie McDoughey",
              },
              {
                id: 2,
                title: "Saturday night live streaming!",
                body: "Join us on Saturday night live at 7:00pm GMT for a quick pod cast session with Neil Longbottom",
              },
            ].map((v, i) => (
              <IonItem key={i}>
                <IonIcon
                  color="primary"
                  icon={notificationsOutline}
                  slot="start"
                ></IonIcon>

                <IonLabel
                  className="ion-text-wrap"
                  onClick={() => setMsgRead(v.id)}
                >
                  <h3 className={`${msgRead ? "color-gray" : ""}`}>{v.title}</h3>
                  <p>{v.body}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Notifications;
