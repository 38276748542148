import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";

import {
  arrowBackOutline,
} from "ionicons/icons";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Subscription from "../components/Subscription";
import Toolbar from "../components/Toolbar";
import { useAppSelector } from "../hooks/useDispatchSelector";
import { selectSubscriptions } from "../store/slices/subscriptionSlice";

const Subscriptions: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [renewal, setRenewal] = useState(true);
  const { subscriptions } = useAppSelector(selectSubscriptions);

  console.log("rendering subscriptions page");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Pricing & Plans</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Pricing & Plans</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonSlides
          options={{
            speed: 400,
            spaceBetween: 20,
            // autoplay: { delay: 5000 },
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
            breakpoints: {
              320: {
                slidesPerView: 1.2,
              },
              700: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: "auto",
                spaceBetween: 40,
              },
              // 1440: {
              //   slidesPerView: 4.2,
              //   spaceBetween: 40,
              // },
            },
          }}
          className="pricing-slide"
        >
          <IonSlide>
            <IonCard className="pricing-card" color="primary">
              <IonCardContent className="ion-text-start">
                <h3 className="ion-text-capitalize">
                  Save More <br />
                  <strong className="">With Goodplans.</strong>
                </h3>
                <p>
                  Choose a plan and get onboard in minuetes. Then get $100
                  credit for your next payment.
                </p>
                <IonItem
                  color="primary"
                  lines="none"
                  style={{ margin: "auto -20px" }}
                >
                  <IonLabel>Renew Monthly/Yearly </IonLabel>
                  <IonToggle
                    checked={renewal}
                    onIonChange={() => setRenewal(!renewal)}
                    color="success"
                  />
                </IonItem>
              </IonCardContent>
            </IonCard>
          </IonSlide>
          {subscriptions.map((sub) => (
            <Subscription subscription={sub} renewal={renewal} key={sub.id} />
          ))}
        </IonSlides>
      </IonContent>
      <Toolbar pageURL={location.pathname} />
    </IonPage>
  );
};

export default Subscriptions;
