import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackOutline,
  chatboxEllipsesOutline,
  micOffOutline,
  micOutline,
  recordingOutline,
  shareOutline,
  stopCircleOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";
import Chat from "../components/Chat";
import Video from "../components/Video";

import useLive from "../hooks/useLive";

const Live: React.FC = () => {
  const {
    messages,
    socket,
    roomId,
    isOpen,
    setIsOpen,
    setMessages,
    users,
    myVideoRef,
    screenData,
    screenVideoRef,
    shareScreen,
    auth,
    newMessageCount,
    setNewMessageCount,
    stopScreenShare,
    myStreamMuted,
    muteStream,
    recordScreen,
    stopRecordScreen,
    chunks,
  } = useLive();
  const history = useHistory();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Live</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          {screenData ? (
            <div style={{ flexGrow: 1 }}>
              {screenData?.username && screenData?.userId !== auth?.user?.id ? (
                <p>{screenData.username} is presenting</p>
              ) : null}
              <video
                ref={screenVideoRef}
                playsInline
                autoPlay
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          ) : null}

          <div style={{ overflowX: "scroll" }}>
            {users.length
              ? users.map((user: any) => (
                  <Video user={user} key={user.userId} />
                ))
              : null}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                setIsOpen(true);
                setNewMessageCount(0);
              }}
            >
              <IonIcon icon={chatboxEllipsesOutline} color="secondary" />
              {newMessageCount ? (
                <IonBadge
                  color="danger"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: -5,
                    fontSize: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {newMessageCount}
                </IonBadge>
              ) : null}
            </IonButton>
            {screenData ? (
              screenData?.userId === auth?.user?.id ? (
                <IonButton onClick={stopScreenShare}>
                  <IonIcon icon={stopCircleOutline} color="secondary" />
                </IonButton>
              ) : null
            ) : (
              <IonButton onClick={shareScreen}>
                <IonIcon icon={shareOutline} color="secondary" />
              </IonButton>
            )}
            <IonButton onClick={muteStream}>
              <IonIcon
                icon={myStreamMuted ? micOffOutline : micOutline}
                color="secondary"
              />
            </IonButton>
            {screenData?.userId === auth?.user?.id ? (
              chunks.length ? (
                <IonButton onClick={stopRecordScreen}>
                  <IonIcon icon={stopCircleOutline} color="danger" />
                </IonButton>
              ) : (
                <IonButton onClick={recordScreen}>
                  <IonIcon icon={recordingOutline} color="secondary" />
                </IonButton>
              )
            ) : null}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <video
        playsInline
        ref={myVideoRef}
        autoPlay
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100px",
          height: "150px",
          zIndex: 500,
          borderRadius: 5,
          objectFit: "cover",
        }}
      />
      <Chat
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        messages={messages}
        roomId={roomId}
        socket={socket}
        setMessages={setMessages}
        setNewMessageCount={setNewMessageCount}
      />
    </IonPage>
  );
};

export default Live;
