import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { qrCodeOutline } from "ionicons/icons";
// import '../styles/Verify.css';

const Verify: React.FC = () => {
  return (
    <IonRow className="h-full">
      <IonCol
        size="12"
        sizeLg="6"
        offsetLg="3"
        className="mt-auto mb-auto"
      ></IonCol>
      <IonCol size="12" sizeLg="6" offsetLg="3">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <h1>Verify Code</h1>
            </IonCardTitle>
            <p>
              Fill in the otp code sent to your email. Otp last for 30 seconds
            </p>
          </IonCardHeader>
          <IonCardContent>
            <form className="md-mt-40  mb-auto">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol size="12">
                    <IonItem color="clear">
                      <IonIcon icon={qrCodeOutline} slot="start" />
                      <IonInput
                        type="number"
                        name="otp"
                        placeholder="Enter code here"
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" sizeMd="5">
                    <IonItem
                      color="tertiary"
                      button
                      lines="none"
                      detail
                      routerLink="/pages/explore"
                    >
                      <IonLabel>Verify Code </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" sizeMd="2"></IonCol>
                  <IonCol size="12" sizeMd="5">
                    <IonItem color="secondary" button lines="none" detail>
                      <IonLabel>Resend code </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default Verify;
