import { CollectionNames } from "../enums";
import {
  onSnapshot,
  collection,
  db,
  query,
  where,
  documentId,
  getDocs,
} from "../firebase.config";
import { formatDate } from "../lib";

const subscriptionsService = {
  watchSubscriptions: (updateSubscriptions: (data: any) => void) => {
    const q = query(collection(db, CollectionNames.SUBSCRIPTIONS));
    const sub = onSnapshot(q, (querySnapshots) => {
      const subscriptions: any = [];

      if (!querySnapshots.empty) {
        querySnapshots.forEach((d) => {
          let item = {
            id: d.id,
            ...d.data(),
          };

          formatDate(item);
          subscriptions.push(item);
        });
      }

      updateSubscriptions(subscriptions);
    });

    return sub;
  },

  fetchFeatures: async (featuresIds: string[]): Promise<string[]> => {
    const q = query(
      collection(db, CollectionNames.FEATURES),
      where(documentId(), "in", featuresIds),
    );
    const querySnapshots = await getDocs(q);
    const features: any = [];
    if (!querySnapshots.empty) {
      querySnapshots.forEach((d) => {
        features.push(d.data().description);
      });
    }

    return features;
  },
};

export default subscriptionsService;
