import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Storage } from "@capacitor/storage";
import moment from "moment";

export const baseURL = "https://vernacular-academy.uc.r.appspot.com";
// export const baseURL = "https://vat-vid.onrender.com";
// export const baseURL = "http://localhost:4000";

export const connectConfig = {
  // host: "localhost",
  host: "vernacular-academy.uc.r.appspot.com",
  // host: "vat-vid.onrender.com/",
  // port: 4000,
  // port: 80,
  port: 443,
  path: "/peerjs",
};

export const toast = async (
  text: string,
  header: string = "Error",
  success: boolean = false,
) => {
  const toast = document.createElement("ion-toast");
  toast.header = header;
  toast.message = text;
  toast.position = "bottom";
  toast.duration = 5000;
  toast.cssClass = "vat-toast";

  document.body.appendChild(toast);
  await toast.present();
};

export const getToastOptions = (message: string) => ({
  duration: 5000,
  message,
});

export const getLoaderOptions = (message: string = "Please wait...") => ({
  message,
});

export const showLoader = async (message: string = "Please wait...") => {
  const loading = document.createElement("ion-loading");

  loading.cssClass = "vat-loader";
  loading.message = message;

  document.body.appendChild(loading);
  await loading.present();
  return loading;
};

export const hideLoader = async (loading: HTMLIonLoadingElement) => {
  await loading.dismiss();
};

export const handleError = (error: any) => {
  if (error.code) {
    switch (error.code) {
      // Authentication Errors
      case "auth/email-already-exists":
        return "Email is already in use by an existing user.";
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/invalid-password":
        return "Password must be a string with at least six characters";
      case "auth/user-not-found":
      case "auth/wrong-password":
        return "Email/Password incorrect";
      default:
        // return "Oops something went wrong. Please try again, if error persist, please contact admin.";
        return `${error.message}`;
    }
  } else {
    return error.message;
  }
};

export const setData: (key: string, value: string) => Promise<void> = async (
  key,
  value,
) => {
  await Storage.set({
    key,
    value,
  });
};

export const getData: (key: string) => Promise<string | null> = async (key) => {
  const { value } = await Storage.get({ key });
  return value;
};

export const removeData: (key: string) => Promise<void> = async (key) => {
  await Storage.remove({ key });
};

export const getPhoto = async () => {
  const image = await Camera.getPhoto({
    quality: 100,
    allowEditing: true,
    resultType: CameraResultType.DataUrl,
    source: CameraSource.Camera,
  });

  return image;
};

export const formatDate = (obj: any) => {
  if (obj.createdAt) {
    if (typeof obj.createdAt !== "string" && obj.createdAt?.toDate()) {
      obj.createdAt = moment(obj.createdAt.toDate()).format(
        "DD MMM YYYY @ hh:mm:ssa",
      );
    } else {
      return moment(obj.createdAt).format("DD MMM YYYY @ hh:mm:ssa");
    }
  }

  if (obj.updatedAt) {
    if (typeof obj.updatedAt !== "string" && obj.updatedAt?.toDate()) {
      obj.updatedAt = moment(obj.updatedAt.toDate()).format(
        "DD MMM YYYY @ hh:mm:ssa",
      );
    } else {
      return moment(obj.updatedAt).format("DD MMM YYYY @ hh:mm:ssa");
    }
  }
};

/**
 * Helper function to manually fire an event
 *
 * @param el the element
 * @param etype the event type
 */
export const eventFire = (el: any, etype: any) => {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
};

/**
 * Helper function to combine classes
 *
 * @param classes function string paramaters
 */
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
