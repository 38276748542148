import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ISubscription } from "../../types";

// Define a type for the slice state
export interface IInitialState {
  subscriptions: ISubscription[];
}

// Define the initial state using that type
const initialState: IInitialState = {
  subscriptions: [],
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    loadSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
  },
});

export const { loadSubscriptions } = subscriptionsSlice.actions;
export const selectSubscriptions = (state: RootState) => state.subscriptions;

const subscriptionsReducer = subscriptionsSlice.reducer;
export default subscriptionsReducer;
