import React, { useEffect, useRef } from "react";

interface Props {
  user: any;
}
const Video: React.FC<Props> = ({ user }) => {
  const videoRef = useRef<any>();

  useEffect(() => {
    console.log("herrr");
    videoRef.current.srcObject = user.stream;
  }, [user.stream]);
  return (
    <div
      className="mx-1"
      style={{
        position: "relative",
        width: "100%",
        height: "200px",
        borderRadius: 5,
        margin: "0 2px 0 2px",
        border: `1px solid ${
          user.tutor ? "yellow" : user.admin ? "green" : "black"
        }`,
      }}
    >
      <p
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          backgroundColor: "black",
          color: "white",
          padding: "3px",
          fontSize: "8px",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        {user.username}
      </p>
      <video
        ref={videoRef}
        playsInline
        autoPlay
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: 3,
        }}
      />
    </div>
  );
};

export default Video;
