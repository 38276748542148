import { useParams } from "react-router";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { io, Socket } from "socket.io-client";
import Peer from "peerjs";
import { selectUser } from "../store/slices/authSlice";
import { useAppDispatch, useAppSelector } from "./useDispatchSelector";
import schedulersService from "../services/schedulers.service";
import tutorService from "../services/tutor.service";
import { useCallback, useEffect, useRef, useState } from "react";
import { baseURL, connectConfig, handleError, toast } from "../lib";
import authService from "../services/auth.service";
import { ISchedule } from "../types";
import { Unsubscribe } from "firebase/firestore";
import { useFormik } from "formik";
import * as yup from "yup";
import { sendMessageAction } from "../store/actions/scheduler.actions";
import { onSnapshot, db, doc } from "../firebase.config";
import { CollectionNames } from "../enums";
import lessonsService from "../services/lessons.service";
import { getMaterialsAction } from "../store/actions/materials.actions";

const useClassroom = () => {
  const dispatch = useAppDispatch();
  const { roomID } = useParams<{ roomID: string }>();
  const { user, tutor } = useAppSelector(selectUser);
  const [data, setData] = useState<ISchedule | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<any>(null);

  const messageForm = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: yup.object({
      message: yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(
          sendMessageAction({
            message: values.message,
            scheduleId: roomID,
          }),
        ).unwrap();
        resetForm();
      } catch (error) {
        console.log(error);
        // toast(handleError(error));
      }
    },
  });

  const { data: lesson } = useQuery({
    queryKey: ["lessons", data?.lesson.id],
    queryFn: async () => await lessonsService.getLessonById(data!.lesson.id),
    enabled: data?.lesson ? true : false,
  });

  const {
    status: materialsStatus,
    error: materialsError,
    data: materials,
  } = useQuery({
    queryKey: ["materials"],
    queryFn: async () =>
      await dispatch(getMaterialsAction(lesson!.materials)).unwrap(),
    enabled: lesson?.materials ? true : false,
  });

  const startClass = useMutation(async () => {
    await tutorService.startClass(roomID);
  });

  useEffect(() => {
    if (startClass.status === "error" && startClass.error) {
      toast(handleError(startClass.error));
    }
  }, [startClass.error, startClass.status]);

  useEffect(() => {
    if (startClass.status === "error" && startClass.error) {
      toast(handleError(startClass.error));
    }
  }, [startClass.error, startClass.status]);

  //   handle socket and peer
  const [users, setUsers] = useState<
    Array<{
      username: string;
      userId: string;
      socketId: string;
      tutor: boolean;
      admin: boolean;
      profilePic: { path: string; url: string } | null;
      stream: MediaStream;
    }>
  >([]);

  const [socket, setSocket] = useState<Socket | null>(null);
  const [myStream, setMyStream] = useState<MediaStream>();
  const myVideoRef = useRef<any>();
  const [myPeer, setMyPeer] = useState<any>();
  const [myStreamMuted, setMyStreamMuted] = useState<boolean>(false);
  const [screenPeer, setScreenPeer] = useState<any>();
  const [screenData, setScreenData] = useState<any>(null);
  const screenVideoRef = useRef<any>();
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);
  const [chunks, setChunks] = useState<any[]>([]);

  // watch class
  useEffect(() => {
    if (roomID && !data?.id && !loaded) {
      onSnapshot(
        doc(db, CollectionNames.SCHEDULERS, roomID),
        (classRoomDoc) => {
          if (!classRoomDoc.exists()) {
            setError(new Error("Class not found"));
            setLoaded(true);
          } else {
            setData({
              id: classRoomDoc.id,
              ...classRoomDoc.data(),
            } as ISchedule);
            setLoaded(true);
          }
        },
        (err) => {
          setError(err);
          setLoaded(true);
        },
      );

      // schedulersService.watchClassRoom(
      //   roomID,
      //   (classRoom) => {
      //     setData((prevState) => {
      //       if (prevState) {
      //         return {
      //           ...prevState,
      //           ...classRoom,
      //         };
      //       }
      //       return classRoom;
      //     });
      //     setLoaded(true);
      //   },
      //   (err) => {
      //     setError(err);
      //     setLoaded(true);
      //   },
      // );
    }

    return () => {
      // if (unSub) {
      //   unSub();
      //   console.log("unsubscribed");
      // }

      if (myStream) {
        myStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [data?.id, roomID, loaded, myStream]);

  const recordScreen = async () => {
    try {
      // const captureStream = await navigator.mediaDevices.getDisplayMedia({
      //   video: true,
      //   audio: true,
      // });

      // let options = {
      //   audioBitsPerSecond: 128000,
      //   videoBitsPerSecond: 2500000,
      //   mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"',
      // };

      // if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      //   options = { ...options, mimeType: "video/webm; codecs=vp9" };
      // } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
      //   options = { ...options, mimeType: "video/webm; codecs=vp8" };
      // } else {
      //   toast(
      //     "Your device does not have the capabilities to record screen",
      //     "Screen Recorder",
      //   );
      // }

      // if (MediaRecorder.isTypeSupported(options.mimeType)) {
      //   const mR = new MediaRecorder(captureStream, options);
      //   setMediaRecorder(mR);
      //   mR.ondataavailable = (event) => {
      //     console.log(event.data);
      //     if (event.data.size > 0) {
      //       setChunks((prevState) => [...prevState, event.data]);
      //     }
      //   };
      // }
      mediaRecorder.start(100);
    } catch (error) {
      console.log(error);
      // toast(handleError(error), "Recording")
    }
  };

  const stopRecordScreen = () => {
    mediaRecorder.stop();
    let blob = new Blob(chunks, {
      type: "video/webm",
    });
    // let url = URL.createObjectURL(blob);
    // let a = document.createElement("a");
    // document.body.appendChild(a);
    // a.style.display = "none";
    // a.href = url;
    // a.download = "test.webm";
    // a.click();
    // window.URL.revokeObjectURL(url);
    const metadata = {
      contentType: "video/webm",
      lessonId: data!.lesson.id,
      scheduleId: data!.id,
      accessLevel: data!.access.level,
    };

    schedulersService.uploadRecording(blob, metadata);
    setChunks([]);
  };

  const shareScreen = async () => {
    try {
      if (
        (screenData && screenData.userId !== user?.id) ||
        user?.role !== "tutor"
      )
        throw new Error("Cannot present screen");

      const peer = new Peer("1234", connectConfig);
      setScreenPeer(peer);

      peer.on("open", async () => {
        const captureStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });

        // let options = {
        //   audioBitsPerSecond: 128000,
        //   videoBitsPerSecond: 2500000,
        //   mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"',
        // };

        // if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
        //   options = { ...options, mimeType: "video/webm; codecs=vp9" };
        // } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
        //   options = { ...options, mimeType: "video/webm; codecs=vp8" };
        // } else {
        //   toast(
        //     "Your device does not have the capabilities to record screen",
        //     "Screen Recorder",
        //   );
        // }

        // if (MediaRecorder.isTypeSupported(options.mimeType)) {
        //   const mixedStream = new MediaStream([
        //     ...captureStream.getTracks(),
        //     ...(myStream as MediaStream).getAudioTracks(),
        //   ]);
        //   const mR = new MediaRecorder(mixedStream, options);
        //   setMediaRecorder(mR);
        //   mR.ondataavailable = (event) => {
        //     if (event.data.size > 0) {
        //       setChunks((prevState) => [...prevState, event.data]);
        //     }
        //   };
        //   mR.start(100);
        // }

        socket?.emit("screenShare", {
          roomID,
          username: user?.fullname,
          userId: user?.id,
        });

        setScreenData({
          roomID,
          username: user?.fullname,
          userId: user?.id,
          stream: captureStream,
        });
        // screenVideoRef.current.srcObject = captureStream;
        captureStream.getVideoTracks()[0].onended = () => {
          setScreenData(null);
          socket?.emit("screenShareStop");
          peer?.disconnect();
          setScreenPeer(null);
          setChunks([]);
        };

        users.forEach((user) => {
          peer.call(user.userId, captureStream);
        });

        socket?.on("reconnect", ({ userId, socketId }) => {
          socket?.emit("screenShareReconnect", {
            username: user?.fullname,
            userId: user?.id,
            to: socketId,
          });
          peer.call(userId, captureStream);
        });
      });
    } catch (error) {
      console.log(error);
      toast(handleError(error), "Share Screen");
    }
  };

  const stopScreenShare = () => {
    // const tracks = screenVideoRef.current.srcObject.getTracks();
    // tracks.forEach((track: any) => track.stop());
    (screenData?.stream as MediaStream)
      .getTracks()
      .forEach((track) => track.stop());
    setScreenData(null);
    socket?.emit("screenShareStop");
    screenPeer.disconnect();
    setScreenPeer(null);

    // mediaRecorder.stop();
    // let blob = new Blob(chunks, {
    //   type: "video/webm",
    // });

    // const metadata = {
    //   contentType: "video/webm",
    //   lessonId: data!.lesson.id,
    //   scheduleId: data!.id,
    //   accessLevel: data!.access.level,
    // };

    // schedulersService.uploadRecording(blob, metadata);
    setChunks([]);
  };

  const endLesson = useCallback(async () => {
    if (screenData) {
      stopScreenShare();
      await tutorService.endClass(roomID);
    }
  }, [roomID, screenData]);

  useEffect(() => {
    if (data?.status === "Past" && myStream) {
      if (myStream) {
        myStream.getTracks().forEach((track) => track.stop());
      }
    }
  }, [data?.status, myStream]);

  const endClass = useMutation(async () => {
    await endLesson();
  });

  const muteStream = () => {
    const enabled = myStream!.getAudioTracks()[0].enabled;
    if (enabled) {
      myStream!.getAudioTracks()[0].enabled = false;
      setMyStreamMuted(true);
    } else {
      myStream!.getAudioTracks()[0].enabled = true;
      setMyStreamMuted(false);
    }
  };

  useEffect(() => {
    const connectToServer = async () => {
      try {
        if (navigator.mediaDevices === undefined)
          throw new Error("You device does not support video call");

        const token = await authService.getAuthToken();
        if (!token) return;
        const s = io(baseURL, {
          auth: {
            token,
          },
        });
        setSocket(s);
        const mS = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100,
          },
          video: {
            facingMode: "user",
          },
        });
        setMyStream(mS);
        myVideoRef.current.srcObject = mS;
        myVideoRef.current.muted = true;

        const peer = new Peer(user?.id || "", connectConfig);
        setMyPeer(peer);
        peer.on("open", () => {
          peer.on("call", (call) => {
            if (call.peer === "1234") {
              call?.answer(myStream);
              call.on("stream", (stream) => {
                setScreenData((prevState: any) => ({ ...prevState, stream }));
                screenVideoRef.current.srcObject = stream;
                // screenVideoRef.current.muted = true;
              });
            } else {
              call.answer(mS);
              call.on("stream", (stream) => {
                setUsers((prevUsers) =>
                  prevUsers.find((u) => u.userId === call.peer)
                    ? prevUsers.map((u) =>
                        u.userId === call.peer ? { ...u, stream } : u,
                      )
                    : [...prevUsers],
                );
              });
            }
          });

          peer.on("error", (error) => {
            console.log(error);
          });

          s.emit("join-room", {
            roomId: roomID,
            userId: user?.id,
            username: tutor?.fullname || user?.fullname,
            tutor: tutor ? true : false,
            admin: false,
            profilePic: tutor?.profilePicture || user?.profilePicture || null,
          });

          s.on("user-joined", (data) => {
            setUsers((prevUsers) => [...prevUsers, { ...data }]);
            s.emit("acknowledgement", {
              userId: user?.id,
              username: user?.fullname,
              socketId: s.id,
              tutor: tutor ? true : false,
              admin: false,
              profilePic: tutor?.profilePicture || user?.profilePicture || null,
              newUserSocketId: data.socketId,
            });

            const call = peer.call(data.userId, mS);
            call.on("stream", (stream) => {
              setUsers((prevUsers) =>
                prevUsers.find((u) => u.userId === data.userId)
                  ? prevUsers.map((u) =>
                      u.userId === data.userId ? { ...u, stream } : u,
                    )
                  : [...prevUsers, { ...data, stream }],
              );
            });
          });

          s.on("acknowledgement", (data) => {
            setUsers((prevUsers) => [...prevUsers, { ...data }]);
          });

          s.on("screenShare", (data) => {
            setScreenData({ ...data });
          });

          s.on("screenShareStop", () => {
            const tracks = screenVideoRef.current.srcObject.getTracks();
            tracks.forEach((track: any) => track.stop());
            setScreenData(null);
          });

          s.on("user-disconnect", (id) => {
            setUsers((prevUsers) =>
              prevUsers.filter(({ userId }) => userId !== id),
            );
          });
        });

        // Error connection
        s.on("connect_error", (err) => {
          console.log(err instanceof Error); // true
          console.log(err.message, "Error"); // not authorized
        });
      } catch (error) {
        console.log(error);
        toast(handleError(error), "Connection");
      }
    };

    if (loaded && data?.status === "Live") {
      console.log("Server");
      connectToServer();
    }
  }, [data?.status, loaded]);

  return {
    data,
    loaded,
    error,
    user,
    tutor,
    startClass,
    // socket
    myVideoRef,
    myStream,
    users,
    muteStream,
    myStreamMuted,
    screenData,
    screenVideoRef,
    shareScreen,
    stopScreenShare,
    recordScreen,
    stopRecordScreen,
    chunks,
    messageForm,
    endClass,
    materialsStatus,
    materialsError,
    materials,
  };
};
export default useClassroom;
