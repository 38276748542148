import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ILesson } from "../../types";
import { fetchLessonsAction } from "../actions/lessons.actions";

// Define a type for the slice state
export interface IInitialState {
  loading: boolean;
  lessons: ILesson[];
}

// Define the initial state using that type
const initialState: IInitialState = {
  loading: false,
  lessons: [],
};

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchLessonsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLessonsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.lessons = action.payload;
      }),
});

export const selectLessons = (state: RootState) => state.lessons;

const lessonsReducer = lessonsSlice.reducer;
export default lessonsReducer;
