import React, { useState, useEffect, useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";

// import required modules
import { FreeMode, Navigation, Thumbs, Controller, Pagination } from "swiper";

const Material = ({ materials }) => {
   // Swiper instance
  const gallerySwiperRef = useRef(null);
  // Swiper thumbsinstance
  const thumbnailSwiperRef = useRef(null);
  // Params definition
  let params = {
    modules: [Controller, Pagination, Navigation],
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: false,
    spaceBetween: 30,
  };
  let thumbsParams = {
    modules: [Controller],
    slideToClickedSlide: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 3,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 4,
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 5,
      },
    },
  };

  // Bind swiper and swiper thumbs
  useEffect(() => {
    const gallerySwiper = gallerySwiperRef.current.swiper;
    const thumbnailSwiper = thumbnailSwiperRef.current.swiper;
    if (gallerySwiper.controller && thumbnailSwiper.controller) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, []);

  return (
    <section className="gallery-section">
      <div className="gallery">
        {/* main swiper */}
        <div className="gallery-container">
          <Swiper {...params} ref={gallerySwiperRef} className="gallery-swiper">
            {materials.map(
              ({ id, learningObjectives, type, poster: { url } }) => (
                <SwiperSlide key={`slide_${id}`}>
                  {type.split("/")[0] === "image" ? (
                    <img
                      key={url}
                      src={url}
                      className="swiper-lazy h-full w-full object-contain"
                    />
                  ) : (
                    <video
                      controls
                      className="m-0 w-full h-full object-contain"
                      autoPlay
                      muted
                      loop
                    >
                      <source src={url} type="video/mp4" />
                      Sorry, your browser doesn&apos;t support embedded videos.
                    </video>
                  )}
                  <div
                    className={`absolute ${
                      type.split("/")[0] === "image" ? "bottom-0" : "top-0"
                    } w-full flex justify-space-between items-center ion-padding bg-overlay`}
                  >
                    <h1 className="text-white">{learningObjectives}</h1>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>

        {/* thumbs swiper */}
        <div className="thumb-container">
          <Swiper
            {...thumbsParams}
            ref={thumbnailSwiperRef}
            className="thumb-swiper"
          >
            {materials.map(({ type, poster: { url } }) => (
              <SwiperSlide key={url}>
                {type.split("/")[0] == "image" ? (
                  <img
                    key={url}
                    src={url}
                    className="swiper-lazy h-full w-full object-contain"
                  />
                ) : (
                  <video
                    className="w-full h-full object-contain"
                    autoPlay
                    muted
                    loop
                  >
                    <source src={url} type="video/mp4" />
                    Sorry, your browser doesn&apos;t support embedded videos.
                  </video>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Material;
