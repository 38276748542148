import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useLocation } from "react-router";
import Notifications from "../components/Notifications";
import Toolbar from "../components/Toolbar";

const Games: React.FC = () => {
  const location = useLocation()
  console.log("rendering games page")

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <Notifications />
          </IonButtons>
          <IonTitle>Games</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Games</IonTitle>
          </IonToolbar>
        </IonHeader>
      </IonContent>
     <Toolbar pageURL={location.pathname}/>
    </IonPage>
  );
};

export default Games;
