import {
  IonButton,
  IonCol,
  IonIcon,
  IonPopover,
  IonRange,
  IonRow,
  IonToggle,
} from "@ionic/react";
import {
  addOutline,
  analyticsOutline,
  barbellOutline,
  colorFillOutline,
  colorPaletteOutline,
  colorWandOutline,
  ellipseOutline,
  moveOutline,
  pencilOutline,
  squareOutline,
  textOutline,
  trashOutline,
} from "ionicons/icons";

import { Fragment, memo, useEffect, useState } from "react";

import { SketchField, Tools } from "./WhiteBoard";
import { CompactPicker } from "react-color";

const SketchPad = memo(() => {
  let _sketch;

  const [settings, setSettings] = useState({
    lineWidth: 10,
    lineColor: "#000000",
    fillColor: "#68CCCA",
    backgroundColor: "#eeeeee",
    shadowWidth: 0,
    shadowOffset: 0,
    tool: Tools.Select,
    enableRemoveSelected: false,
    fillWithColor: false,
    fillWithBackgroundColor: false,
    drawings: [],
    canUndo: false,
    canRedo: false,
    controlledSize: false,
    sketchWidth: 600,
    sketchHeight: 600,
    stretched: true,
    stretchedX: false,
    stretchedY: false,
    originX: "left",
    originY: "top",
    imageUrl: "https://files.gamebanana.com/img/ico/sprays/4ea2f4dad8d6f.png",
    expandTools: false,
    expandControls: false,
    expandColors: false,
    expandBack: false,
    expandImages: false,
    expandControlled: false,
    text: "a text, cool!",
    enableCopyPaste: false,
  });

  const selectTool = (selectedTool) => {
    setSettings({
      ...settings,
      tool: selectedTool,
      enableRemoveSelected: selectedTool === Tools.Select,
      enableCopyPaste: selectedTool === Tools.Select,
    });
  };

  const removeSelected = () => {
    _sketch.removeSelected();
  };

  const onSketchChange = () => {
    let prev = settings.canUndo;
    let now = _sketch.canUndo();
    if (prev !== now) {
      setSettings({ ...settings, canUndo: now });
    }
  };

  const addText = () => _sketch.addText(settings.text);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    if (settings.tool === Tools.Select)
      window.addEventListener("keydown", (e) =>
        e.code == "Backspace" || e.code == 8
          ? !!_sketch?.hasSelection()
            ? removeSelected()
            : false
          : false
      );
  }, [settings.tool]);

  return (
    <IonRow>
      <IonCol size="12" sizeMd="11">
        <SketchField
          name="sketch"
          className="canvas-area border overflow-hidden"
          ref={(c) => (_sketch = c)}
          lineColor={settings.lineColor}
          lineWidth={settings.lineWidth}
          fillColor={
            settings.fillWithColor ? settings.fillColor : "transparent"
          }
          backgroundColor={
            settings.fillWithBackgroundColor
              ? settings.backgroundColor
              : "#eeeeee"
          }
          width={settings.controlledSize ? settings.sketchWidth : null}
          height={settings.controlledSize ? settings.sketchHeight : null}
          // value={settings}
          forceValue
          onChange={onSketchChange}
          autoresize={true}
          tool={settings.tool}
        />
      </IonCol>
      <IonCol size="12" sizeMd="1">
        <div className="flex flex-wrap items-center w-full">
          {[
            {
              title: "Select tool",
              tool: Tools.Select,
              icon: moveOutline,
            },
            {
              title: "Free hand pencil",
              tool: Tools.Pencil,
              icon: pencilOutline,
            },
            {
              title: "Draw a rectangle tool",
              tool: Tools.Rectangle,
              icon: squareOutline,
            },
            {
              title: "Draw a circle tool",
              tool: Tools.Circle,
              icon: ellipseOutline,
            },
            {
              title: "Draw a line tool",
              tool: Tools.Line,
              icon: analyticsOutline,
            },
          ].map(({ tool, icon, title }, index) => (
            <IonButton
              key={icon}
              onClick={() => selectTool(tool)}
              color={settings.tool == tool ? "primary" : "light"}
              title={title}
              className="animate__animated animate__pulse"
              style={{ animationDelay: `${index}s` }}
            >
              <IonIcon icon={icon}></IonIcon>
            </IonButton>
          ))}

          {/* text tool */}
          <IonButton
            id="text-tool"
            color={"light"}
            title={"Text tool"}
            className="animate__animated animate__pulse"
            style={{ animationDelay: `6s` }}
          >
            <IonIcon icon={textOutline}></IonIcon>
          </IonButton>
          <IonPopover trigger="text-tool" triggerAction="click">
            <div className="ion-padding">
              <div className="flex">
                <input
                  value={settings.text}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      text: e.target.value,
                    })
                  }
                  className="w-full mr-8"
                />
                <IonButton
                  title={"Add text to whiteboard"}
                  onClick={addText}
                  className="m-0"
                >
                  <IonIcon icon={addOutline}></IonIcon>
                </IonButton>
              </div>
            </div>
          </IonPopover>

          {/* bg color */}
          <IonButton
            id="bg-color-tool"
            color={"light"}
            title={"Background color"}
            className="animate__animated animate__pulse"
            style={{ animationDelay: `6s` }}
          >
            <IonIcon icon={colorWandOutline}></IonIcon>
          </IonButton>
          <IonPopover trigger="bg-color-tool" triggerAction="click">
            <div className="ion-padding">
              <div className="flex items-center justify-space-between mb-16">
                Background color
                <IonToggle
                  checked={settings.fillWithBackgroundColor}
                  onIonChange={() =>
                    setSettings({
                      ...settings,
                      fillWithBackgroundColor:
                        !settings.fillWithBackgroundColor,
                    })
                  }
                  enableOnOffLabels={true}
                />
              </div>
              <CompactPicker
                color={settings.backgroundColor}
                onChange={(color) =>
                  setSettings({
                    ...settings,
                    backgroundColor: color.hex,
                  })
                }
              />
            </div>
          </IonPopover>

          {settings.tool === Tools.Select ? (
            <Fragment>
              {/* line weight */}
              <IonButton
                id="weight-tool"
                color={"light"}
                title={"Line weight tool"}
                className="animate__animated animate__pulse"
                style={{ animationDelay: `1s` }}
              >
                <IonIcon icon={barbellOutline}></IonIcon>
              </IonButton>
              <IonPopover trigger="weight-tool" triggerAction="click">
                <div className="ion-padding">
                  <div className="flex items-center justify-space-between">
                    Line width
                  </div>
                  <IonRange
                    pin={true}
                    defaultValue={settings.lineWidth}
                    pinFormatter={(value) => `${value}`}
                    min={1}
                    step={1}
                    max={100}
                    value={settings.lineWidth}
                    onIonChange={({ detail }) =>
                      setSettings({ ...settings, lineWidth: detail.value })
                    }
                    style={{ width: 100 }}
                  ></IonRange>
                </div>
              </IonPopover>

              {/* line color */}
              <IonButton
                id="line-color-tool"
                color={"light"}
                title={"Change line color"}
                className="animate__animated animate__pulse"
                style={{ animationDelay: `2s` }}
              >
                <IonIcon icon={colorPaletteOutline}></IonIcon>
              </IonButton>
              <IonPopover trigger="line-color-tool" triggerAction="click">
                <div className="ion-padding">
                  <div className="flex items-center justify-space-between mb-16">
                    Line color
                  </div>
                  <CompactPicker
                    color={settings.lineColor}
                    onChange={(color) =>
                      setSettings({
                        ...settings,
                        lineColor: color.hex,
                      })
                    }
                  />
                </div>
              </IonPopover>

              {/* fill color */}
              <IonButton
                id="fill-color-tool"
                color={"light"}
                title={"fill color"}
                className="animate__animated animate__pulse"
                style={{ animationDelay: `3s` }}
              >
                <IonIcon icon={colorFillOutline}></IonIcon>
              </IonButton>
              <IonPopover trigger="fill-color-tool" triggerAction="click">
                <div className="ion-padding">
                  <div className="flex items-center justify-space-between mb-16">
                    Fill color
                    <IonToggle
                      checked={settings.fillWithColor}
                      onIonChange={() =>
                        setSettings({
                          ...settings,
                          fillWithColor: !settings.fillWithColor,
                        })
                      }
                      enableOnOffLabels={true}
                    />
                  </div>
                  <CompactPicker
                    color={settings.fillColor}
                    onChange={(color) =>
                      setSettings({
                        ...settings,
                        fillColor: color.hex,
                      })
                    }
                  />
                </div>
              </IonPopover>

              {/* delete selected */}
              <IonButton
                onClick={() => removeSelected()}
                color={"light"}
                title={"Delete selected item"}
                className="animate__animated animate__pulse"
                style={{ animationDelay: `4s` }}
              >
                <IonIcon icon={trashOutline} color="danger"></IonIcon>
              </IonButton>
            </Fragment>
          ) : null}
        </div>
      </IonCol>
    </IonRow>
  );
});

export default SketchPad;
