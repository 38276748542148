import { LocalNotifications } from "@capacitor/local-notifications";
import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonChip,
  IonIcon,
  IonAvatar,
  IonButton,
  useIonAlert,
} from "@ionic/react";
import { schoolOutline, alarmOutline, videocamOutline } from "ionicons/icons";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import { useAppSelector } from "../hooks/useDispatchSelector";
import { selectUser } from "../store/slices/authSlice";
import { ISchedule } from "../types";
import Attendees from "./Attendees";
import schedulersService from "../services/schedulers.service";

interface Props {
  up: ISchedule;
}
const Schedule: React.FC<Props> = ({ up }) => {
  const { user } = useAppSelector(selectUser);
  const [presentAlert] = useIonAlert();
  const queryClient = useQueryClient();
  const mutation = useMutation(async () => {
    await schedulersService.attendSchedule(up.id, user!.id);

    await LocalNotifications.schedule({
      notifications: [
        {
          title: up.lesson.title,
          body: up.description,
          id: 1,
          schedule: {
            at: new Date(
              +moment()
                .add(
                  +moment(`${up.eventDate} ${up.start}`, "YYYY-MM-DD HH:mm"),
                  "millisecond",
                )
                .subtract(10, "minutes"),
            ),
          },
          sound: undefined,
          attachments: undefined,
          actionTypeId: "",
          extra: null,
        },
      ],
    });
    queryClient.invalidateQueries("schedulers");
    return;
  });

  return (
    <IonRow className="upcoming-lesson-card">
      <IonCol size="12" sizeMd="2">
        <div className="h-full flex items-center justify-center">
          <div className="w-full text-center md-after-line">
            <h3 className="font-bold text-md lg-text-lg">
              {moment(up.eventDate, "YYYY-MM-DD").format("DD")}
            </h3>
            <p className="uppercase font-light lg-text-sm">
              {moment(up.eventDate, "YYYY-MM-DD").format("ddd")}
            </p>
          </div>
        </div>
      </IonCol>
      <IonCol size="12" sizeMd="10" className="p-10">
        <h3 className="font-bold">{up.lesson.title}</h3>
        <p className="mt-16">{up.description}</p>
      </IonCol>
      <IonCol size="12" className="ion-no-padding">
        <div className="divider"></div>
        <IonList>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                <h3>Host</h3>
              </IonText>
              <p>{up.host.name}</p>
            </IonLabel>
            <IonChip slot="end" color="medium">
              Tutor
              <IonIcon icon={schoolOutline} />
            </IonChip>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                <h3>{up.eventDate}</h3>
              </IonText>
              <p>
                Start Time: {moment(up.start, "HH:mm").format("HH:mma")} - Ends:{" "}
                {moment(up.end, "HH:mm").format("HH:mma")}
              </p>
            </IonLabel>
            <IonChip slot="end" color="medium">
              Duration
              <IonIcon icon={alarmOutline} />
            </IonChip>
          </IonItem>
          {up?.attendees?.length ? (
            <Attendees
              attendees={
                up.attendees.length > 10 ? up.attended.slice(10) : up.attendees
              }
            />
          ) : null}
        </IonList>
      </IonCol>
      <IonCol size="12">
        {/* check if event is ongoing */}
        {up.status === "Live" ? (
          <>
            <IonItem
              color="danger"
              detail
              routerLink={`/classroom/${up.id}`}
            >
              <IonIcon
                icon={videocamOutline}
                className="animate__animated  animate__pulse animate__infinite"
                slot="start"
              />
              <IonLabel>Lesson Ongoing - Join!</IonLabel>
            </IonItem>
          </>
        ) : (
          <>
            {/* Check if this user is already attending this event */}
            {up?.attendees?.includes(user?.id!) ? (
              <IonButton expand="block" color="light" className="mt-16">
                Reminder Set
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                color="success"
                className="mt-16"
                disabled={mutation.status === "loading"}
                onClick={() =>
                  presentAlert({
                    header: "Reminder",
                    subHeader: "Reminder set for this event!",
                    message:
                      "You will be alerted 10mins to start of this event, do ensure to attend.",
                    buttons: [
                      {
                        text: "Ok",
                        role: "Continue",
                        handler: () => mutation.mutate(),
                      },
                    ],
                  })
                }
              >
                {mutation.status === "loading" ? "Please wait..." : "Attend"}
              </IonButton>
            )}
          </>
        )}
      </IonCol>
    </IonRow>
  );
};

export default Schedule;
