import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { Timestamp } from "firebase/firestore";
import {
  cardOutline,
  chatbubblesOutline,
  diamondOutline,
  giftOutline,
  hourglassOutline,
  informationCircleOutline,
  lockClosedOutline,
  logOutOutline,
  schoolOutline,
  storefrontOutline,
  timeOutline,
  timerOutline,
  walletOutline,
} from "ionicons/icons";
import moment from "moment";
import { Fragment, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import { Router } from "workbox-routing";
import Modal from "../components/Modal";
import Notifications from "../components/Notifications";
import Toolbar from "../components/Toolbar";
import useGeneral from "../hooks/useGeneral";
import useProfile from "../hooks/useProfile";
// import '../styles/Profile.css';

interface SubscriptionDetail {
  name: string;
  createdAt: Timestamp;
  expiresIn: Timestamp;
  amount: any;
  duration: number;
  features: [];
}

const Profile: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    auth: { user },
    signOut,
  } = useGeneral();
  const [presentAlert] = useIonAlert();
  const { changePassword, changeProfilePics } = useProfile(user!);
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };

  const [sd, SubscriptionDetail] = useState<SubscriptionDetail | null>(null);
  const openDetails = (data: any) => {
    SubscriptionDetail(data);
    handleModal();
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <Notifications />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard className="profile-image-card">
          <div className="flex ion-padding items-center">
            <button
              type="button"
              onClick={changeProfilePics}
              className="profile-image edit"
            >
              <img
                src={user?.profilePicture?.url || "assets/icon/icon.png"}
                alt="profile"
                ref={(e) => {
                  e?.addEventListener("error", (_) =>
                    e.setAttribute("src", "assets/icon/icon.png"),
                  );
                }}
              />
            </button>
            <div>
              <IonCardHeader>
                <h2>{user?.fullname}</h2>
                <p>Profile created: {user?.createdAt}</p>
              </IonCardHeader>
            </div>
          </div>
        </IonCard>

        {[
          {
            header: "Transactions",
            links: [
              {
                name: "Payments History",
                handleClick: () => {},
                href: "/payments",
                icon: cardOutline,
              },
              {
                name: "Subscription",
                handleClick: () => {
                  if (
                    !user?.subscription.expiresIn &&
                    moment(user?.subscription.expiresIn?.toDate()).diff(
                      moment(),
                      "days",
                    ) < 1
                  ) {
                    presentAlert({
                      header: "Subscription",
                      subHeader: "No active subscription!",
                      message:
                        "Please see Pricing & Plans for more details on our various subscriptions.",
                      buttons: [
                        {
                          text: "Cancel",
                          role: "Cancel",
                        },
                        {
                          text: "Continue",
                          role: "Continue",
                          handler: () => history.push("/subscriptions"),
                        },
                      ],
                    });
                  } else {
                    openDetails({
                      name: user?.subscription?.description || "Lite",
                      amount: user?.subscription?.amount || 1000,
                      createdAt: user?.subscription.createdAt,
                      expiresIn: user?.subscription.expiresIn,
                      features: user?.subscription.features,
                      duration: user?.subscription?.duration || "12",
                    });
                  }
                },
                href: undefined,
                icon: timerOutline,
              },
            ],
          },
          {
            header: "Support",
            links: [
              {
                name: "About",
                handleClick: () => {},
                href: undefined,
                icon: informationCircleOutline,
              },
              {
                name: "Help",
                handleClick: () => {},
                href: undefined,
                icon: chatbubblesOutline,
              },
            ],
          },
          {
            header: "Partners",
            links: [
              {
                name: "Tutors",
                handleClick: () => {},
                href:
                  user?.role !== "tutor"
                    ? "/tutor-registration"
                    : "/tutor-dashboard",
                icon: schoolOutline,
              },
              {
                name: "Ads Placement",
                handleClick: () => {},
                href: undefined,
                icon: storefrontOutline,
              },
            ],
          },
          {
            header: "Account",
            links: [
              {
                name: "Change Password",
                handleClick: changePassword,
                href: undefined,
                icon: lockClosedOutline,
              },
              {
                name: "Log Out",
                handleClick: signOut,
                href: undefined,
                icon: logOutOutline,
              },
            ],
          },
        ].map((item) => (
          <Fragment key={item.header}>
            <IonListHeader>{item.header}</IonListHeader>
            <IonList inset>
              {item.links.map((link) => (
                <IonItem
                  key={link.name}
                  lines="none"
                  button
                  color="light"
                  onClick={link.handleClick}
                  routerLink={link.href}
                >
                  <IonLabel>{link.name}</IonLabel>
                  <IonIcon icon={link.icon} slot="start" color="primary" />
                </IonItem>
              ))}
            </IonList>
          </Fragment>
        ))}

        <div className="ion-padding">&nbsp;</div>
      </IonContent>

      <Toolbar pageURL={location.pathname} />

      <Modal
        showModal={modal}
        handleModal={handleModal}
        title="Active Subscription"
      >
        <IonCard className="md-m-0">
          <img src="/assets/img/patternbg.jpeg" alt="pattern" />
          <IonCardHeader>
            <IonCardSubtitle>{sd?.name} plan</IonCardSubtitle>
            <IonCardTitle>Subscription Details</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>
              This Subscription has the following features;{" "}
              {sd?.features.join(", ")}
            </p>
          </IonCardContent>
          <IonList>
            {[
              {
                name: "Subscription",
                text: sd?.name,
                icon: diamondOutline,
              },
              {
                name: "Duration",
                text: `${moment(sd?.expiresIn?.toDate()).diff(
                  sd?.createdAt.toDate(),
                  "months",
                )} month(s) plan`,
                icon: hourglassOutline,
              },
              {
                name: "Purchased",
                text: moment(sd?.createdAt?.toDate()).format(
                  "DD-MM-YYYY | hh:mm",
                ),
                icon: timeOutline,
              },
              {
                name: "Renews",
                text: moment(sd?.expiresIn?.toDate()).format(
                  "DD-MM-YYYY | hh:mm",
                ),
                icon: timeOutline,
              },
              {
                name: "Amount",
                text: `$ ${sd?.amount}`,
                icon: cardOutline,
              },
            ].map((item) => (
              <IonItem key={item.name}>
                <div className="link-icon normal" slot="start">
                  <IonIcon icon={item.icon} color="primary" />
                </div>
                <IonLabel>
                  <h2>{item.name}</h2>
                  <p>{item.text}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>

          <IonItem lines="none" className="mt-16">
            <IonButton
              fill="solid"
              routerLink="/subscriptions"
              onClick={() => handleModal()}
            >
              Renew Now
            </IonButton>
          </IonItem>
        </IonCard>
      </Modal>
    </IonPage>
  );
};

export default Profile;
