import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getLoaderOptions,
  getToastOptions,
  handleError,
} from "../lib";
import Auth from "../classes/Auth";
import { useIonLoading, useIonToast } from "@ionic/react";

const useReset = () => {
  const [presentLoader, dismissLoader] = useIonLoading();
  const [present] = useIonToast();
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async ({ email }, { setSubmitting, resetForm }) => {
      presentLoader(getLoaderOptions("Please wait..."));
      try {
        const auth = new Auth();
        await auth.resetPassword(email);
        dismissLoader();
        present(
          getToastOptions(`A password reset email has been sent to ${email}`),
        );
        resetForm();
      } catch (error: any) {
        setSubmitting(false);
        dismissLoader();
        present(getToastOptions(handleError(error)));
      }
    },
  });

  return {
    formik,
  };
};

export default useReset;
