import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import {
  arrowBackOutline,
  arrowBackSharp,
  caretForwardCircleOutline,
  star,
  calendar,
  schoolOutline,
  alarmOutline,
  videocamOutline,
} from "ionicons/icons";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import AlertCard from "../components/AlertCard";
import "../styles/Lesson.css";
import Masonry from "react-smart-masonry";
import { LocalNotifications } from "@capacitor/local-notifications";
import useLesson from "../hooks/useLesson";
import { handleError } from "../lib";
import Schedules from "../components/Schedules";

const Lesson: React.FC = () => {
  const { lessonID, status, error, data, lesson } = useLesson();
  const [outlineReview, setOutlineReview] = useState("outline");
  const history = useHistory();
  const breakpoints = { mobile: 0, tablet: 700, desktop: 1024 };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon ios={arrowBackOutline} md={arrowBackSharp} />
            </IonButton>
          </IonButtons>
          <IonTitle>Lesson Details</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        {lesson || data ? (
          <IonRow>
            <IonCol size="12" sizeMd="5" sizeLg="4">
              <IonCard className="md-h-full md-h-max-400 overflow-y-scroll">
                <img
                  src={
                    (lesson || data)?.poster.url || "/assets/img/patternbg.jpeg"
                  }
                  alt="lesson poster"
                  ref={(e) => {
                    e?.addEventListener("error", (_) =>
                      e.setAttribute(
                        "src",
                        (lesson || data)?.poster.url ||
                          "/assets/img/patternbg.jpeg",
                      ),
                    );
                  }}
                  className="w-full"
                />
                <IonCardHeader>
                  <IonCardSubtitle className="ion-text-capitalize">
                    {(lesson || data)?.language}
                  </IonCardSubtitle>
                  <IonCardTitle>{(lesson || data)?.title}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <p>{(lesson || data)?.description}</p>
                </IonCardContent>
              </IonCard>
            </IonCol>
            {/* Recordings & Reviews */}
            <IonCol size="12" sizeMd="7" sizeLg="8">
              <IonCard className="md-h-full md-h-max-400 overflow-y-scroll">
                <IonCardHeader>
                  <IonSegment
                    color="tertiary"
                    onIonChange={(e) => setOutlineReview(e.detail.value!)}
                    value={outlineReview}
                  >
                    <IonSegmentButton value="outline">
                      Recordings
                    </IonSegmentButton>
                    <IonSegmentButton value="reviews">Reviews</IonSegmentButton>
                  </IonSegment>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  {outlineReview === "outline" &&
                    (false ? (
                      <IonList>
                        {[
                          {
                            thumbnail: "/assets/img/patternbg.jpeg", //base64 image of lesson poster
                            topic: "Module One",
                            description: "Learn how to say Hello! ",
                            duration: 90,
                            createdAt: "25-07-2022",
                          },
                        ].map((v) => (
                          <IonItem key={v.createdAt}>
                            <IonAvatar slot="start">
                              <IonImg src={v.thumbnail} />
                            </IonAvatar>
                            <IonLabel className="ion-text-wrap">
                              <IonText color="primary">
                                <h3>{v.topic}</h3>
                              </IonText>
                              <p>{v.description}</p>
                            </IonLabel>
                            <IonChip slot="end" color="medium">
                              {v.duration} mins
                              <IonIcon icon={caretForwardCircleOutline} />
                            </IonChip>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : (
                      <AlertCard
                        title="No"
                        subtitle="Recordings Here!"
                        message="Sorry, no recordings at the moment please check back later."
                        color="tertiary"
                        noCloseBtn
                      />
                    ))}

                  {outlineReview === "reviews" &&
                    (false ? (
                      <IonList>
                        {[
                          {
                            thumbnail: "/assets/img/patternbg.jpeg", //base64 image of user profile picture
                            name: "McDecoil Philips",
                            body: "Today's lesson was awesome",
                            rating: 90,
                            createdAt: "25-07-2022",
                          },
                        ].map((v) => (
                          <IonItem key={v.createdAt}>
                            <IonAvatar slot="start">
                              <IonImg src={v.thumbnail} />
                            </IonAvatar>
                            <IonLabel className="ion-text-wrap">
                              <IonText color="primary">
                                <h3>{v.name}</h3>
                              </IonText>
                              <p>{v.body}</p>
                            </IonLabel>
                            <IonChip slot="end" color="medium">
                              {v.rating}
                              <IonIcon icon={star} color="warning" />
                            </IonChip>
                          </IonItem>
                        ))}
                      </IonList>
                    ) : (
                      <AlertCard
                        title="No"
                        subtitle="Reviews Here!"
                        message="Sorry, no reviews at the moment please check back later."
                        color="tertiary"
                        noCloseBtn
                      />
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>

            {/* Upcoming lessons */}
            <Schedules lessonId={lessonID} breakpoints={breakpoints} />
          </IonRow>
        ) : status === "loading" ? (
          <IonSpinner className="w-full" />
        ) : status === "error" ? (
          <AlertCard
            title={`Opps`}
            subtitle="Lesson"
            message={handleError(error)}
            color="tertiary"
            noCloseBtn
          />
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default Lesson;
