import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../lib";
import { fetchRecommendedService } from "../../services/recommended.service";
import { ILesson } from "../../types";

export const fetchRecommendedAction = createAsyncThunk<ILesson[], void>(
  "recommended/fetchRecommendedAction",
  async (_, { rejectWithValue }) => {
    try {
      const recommended = await fetchRecommendedService();
      return recommended;
    } catch (error) {
      console.log(error);
      return rejectWithValue(handleError(error));
    }
  },
);
