import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import { Fragment, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import Notifications from "../components/Notifications";
import Toolbar from "../components/Toolbar";
import { useAppSelector } from "../hooks/useDispatchSelector";
import useExplore from "../hooks/useExplore";
import useGeneral from "../hooks/useGeneral";
import { selectLessons } from "../store/slices/lessonsSlice";
import "../styles/Explore.css";
import Masonry from "react-smart-masonry";
import AlertCard from "../components/AlertCard";

const Explore: React.FC = () => {
  const { lessons, loading: loadingLessons } = useAppSelector(selectLessons);
  const location = useLocation();
  const { courseID } = useParams<{ courseID: string }>();
  const {
    auth: { user },
  } = useGeneral();
  const { languages, recommended, languageSelected, setLanguageSelected } =
    useExplore();

  const [searchText, setSearchText] = useState(courseID || "");
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const breakpoints = { mobile: 0, tablet: 700, desktop: 1024 };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <Notifications />
          </IonButtons>
          <IonTitle>Let's Explore</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Hi {user?.fullname.split(" ")[0]}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="ion-padding">
          <IonItem color="light" lines="none">
            <IonInput
              type="search"
              placeholder="Search lessons"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
            />
            <IonButton color="primary" slot="end">
              <IonIcon icon={searchOutline} />
            </IonButton>
          </IonItem>
        </div>
        {recommended?.length > 3 ? (
          <>
            <IonList>
              <IonListHeader>Recommended</IonListHeader>
            </IonList>
            <IonSlides
              ref={slideRef}
              options={{
                speed: 400,
                slidesPerView: "auto",
                slidesOffsetBefore: 8,
                spaceBetween: 8,
                autoplay: { delay: 5000 },
              }}
              className="slides-cards-explore"
            >
              {recommended.map((v, i) => (
                <IonSlide key={i}>
                  <IonCard routerLink={`/lesson/${v.id}`}>
                    <img src={v.poster.url} alt="" className="w-full"/>
                    <IonCardHeader>
                      <IonCardSubtitle>{v.language}</IonCardSubtitle>
                      <IonCardTitle>{v.title}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>{v.description}</IonCardContent>
                  </IonCard>
                </IonSlide>
              ))}
            </IonSlides>
          </>
        ) : null}

        <IonList>
          <IonListHeader>Languages</IonListHeader>
        </IonList>

        <IonRadioGroup
          value={languageSelected}
          className="language-selection ion-padding"
          onIonChange={(e) => setLanguageSelected(e.detail.value)}
        >
          {languages.map(({ id, description }) => (
            <IonButton
              key={id}
              shape="round"
              mode="ios"
              color={languageSelected === description ? "primary" : "light"}
              onClick={(e) => setLanguageSelected(description)}
            >
              <IonRadio
                value={description}
                color={languageSelected === description ? "secondary" : "light"}
              />
              &nbsp;&nbsp;{description}
            </IonButton>
          ))}
        </IonRadioGroup>

        {lessons.length > 0 ? (
          <Masonry
            breakpoints={breakpoints}
            columns={{ mobile: 1, tablet: 2, desktop: 3 }}
            gap={8}
          >
            {lessons.map((course, i) => (
              <IonCard routerLink={`/lesson/${course.id}`} key={i}>
                <img
                  src={course.poster.url || "/assets/img/patternbg.jpeg"}
                  alt="course poster"
                  ref={(e) => {
                    e?.addEventListener("error", (_) =>
                      e.setAttribute("src", "/assets/img/patternbg.jpeg"),
                    );
                  }}
                  className="w-full"
                />
                <IonCardHeader>
                  <IonCardSubtitle className="ion-text-capitalize">
                    {course.language}
                  </IonCardSubtitle>
                  <IonCardTitle>{course.title}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <p>{course.description}</p>
                </IonCardContent>
              </IonCard>
            ))}
          </Masonry>
        ) : loadingLessons ? (
          <IonSpinner className="w-full" />
        ) : (
          <AlertCard
            title={`No ${languageSelected}`}
            subtitle="Lessons Here!"
            message="Please check back later while we update the lessons for this language."
            color="tertiary"
            noCloseBtn
          />
        )}
      </IonContent>

      <Toolbar pageURL={location.pathname} />
    </IonPage>
  );
};

export default Explore;
