import { CollectionNames } from "../enums";
import {
  getDownloadURL,
  ref,
  uploadString,
  storage,
  setDoc,
  db,
  doc,
  onSnapshot,
  timestamp,
  updateDoc,
} from "../firebase.config";
import { formatDate } from "../lib";

const tutorService = {
  watchTutor: (id: string, updateStore: any) => {
    const sub = onSnapshot(doc(db, CollectionNames.TUTORS, id), (docSnap) => {
      if (!docSnap.exists()) {
        updateStore(null);
      } else {
        const data = {
          id: docSnap.id,
          ...docSnap.data(),
        };

        formatDate(data);
        updateStore(data);
      }
    });

    return sub;
  },
  uploadFile: async (
    name: string,
    dataUrl: string,
    filePath: string,
    metadata: any,
  ): Promise<{ url: string; path: string; name: string }> => {
    const imageRef = ref(storage, filePath);
    const res = await uploadString(imageRef, dataUrl, "data_url", metadata);
    const downloadurl = await getDownloadURL(res.ref);

    const data = {
      name,
      url: downloadurl,
      path: res.ref.fullPath,
    };

    return data;
  },

  create: async (document: string, data: any) => {
    await setDoc(doc(db, CollectionNames.TUTORS, document), {
      ...data,
      createdAt: timestamp(),
    });
  },

  update: async (document: string, data: any) => {
    await updateDoc(doc(db, CollectionNames.TUTORS, document), {
      ...data,
      updatedAt: timestamp(),
    });
  },

  startClass: async (id: string) => {
    await updateDoc(doc(db, CollectionNames.SCHEDULERS, id), {
      status: "Live",
      hostStarted: timestamp(),
    });
  },

  endClass: async (id: string) => {
    await updateDoc(doc(db, CollectionNames.SCHEDULERS, id), {
      status: "Past",
      hostEnded: timestamp(),
    });
  },
};

export default tutorService;
