import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { useQuery } from "react-query";
import { handleError } from "../lib";
import authService from "../services/auth.service";
interface Props {
  attendees: string[] | any;
}

const Attendees: React.FC<Props> = ({ attendees }) => {
  const { status, error, data } = useQuery({
    queryKey: ["schedulers", "schedule-attendees"],
    queryFn: async () =>
      authService.getDownloadUrls(attendees.map((id: any) => `profile/${id}`)),
    enabled: attendees?.length ? true : false,
  });
  return (
    <IonItem>
      {status === "loading" ? <IonSpinner className="w-full" /> : null}
      {status === "error" && error ? (
        <IonText color="primary">
          <h3>{handleError(error)}</h3>
        </IonText>
      ) : null}
      {data?.length ? (
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            <h3>Attendees</h3>
          </IonText>
          <div className="h-40 w-full mt-8 py-8">
            {data.map((attendee) => (
              <IonAvatar className="h-30 w-30 mr-8 inline-block" key={attendee}>
                <img src={attendee} alt="attendee-thumbnail" />
              </IonAvatar>
            ))}
          </div>
        </IonLabel>
      ) : null}
    </IonItem>
  );
};

export default Attendees;
