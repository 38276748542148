import { useFormik } from "formik";
import * as Yup from "yup";
import { getLoaderOptions, getToastOptions, handleError } from "../lib";
import Auth from "../classes/Auth";
import User from "../classes/User";
import { timestamp } from "../firebase.config";
import { useIonLoading, useIonToast } from "@ionic/react";

const useSignup = () => {
  const [presentLoader, dismissLoader] = useIonLoading();
  const [present] = useIonToast();
  const validationSchema = Yup.object({
    fullname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    gender: Yup.string().required("Required"),
    dob: Yup.date().required("Required"),
    nationality: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      gender: "Male",
      dob: "",
      nationality: "Nigeria",
      password: "",
    },
    validationSchema,
    onSubmit: async (
      { fullname, dob, email, password, nationality, gender },
      { setSubmitting },
    ) => {
      presentLoader(getLoaderOptions());
      try {
        //   Register user
        const auth = new Auth();
        const userId: string = await auth.register(email, password);

        // create user profile
        const newUser = new User(userId);
        await newUser.createProfile({
          fullname,
          dob,
          email,
          nationality,
          gender,
          createdAt: timestamp(),
        });
        dismissLoader();
      } catch (error: any) {
        dismissLoader();
        present(getToastOptions(handleError(error)));
      }
    },
  });

  return {
    formik,
  };
};

export default useSignup;
