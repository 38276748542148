import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchLanguagesAction } from "../store/actions/languages.actions";
import { fetchLessonsAction } from "../store/actions/lessons.actions";
import { fetchRecommendedAction } from "../store/actions/recommended.actions";
import { selectLanguages } from "../store/slices/languagesSlice";
import { selecteRecommended } from "../store/slices/recommendedSlice";
import { useAppSelector } from "./useDispatchSelector";

const useExplore = () => {
  const [languageSelected, setLanguageSelected] = useState<string>("");
  const { languages } = useAppSelector(selectLanguages);
  const { recommended } = useAppSelector(selecteRecommended);
  const dispatch = useDispatch();

  useEffect(() => {
    if (languages.length > 0) setLanguageSelected(languages[0]["description"]);
  }, [languages]);

  useEffect(() => {
    if (languageSelected) {
      dispatch(fetchLessonsAction(languageSelected));
    }
  }, [dispatch, languageSelected]);

  useEffect(() => {
    if (!languages.length) {
      dispatch(fetchLanguagesAction());
    }
  }, [dispatch, languages]);

  // useEffect(() => {
  //   if (!recommended?.length) {
  //     dispatch(fetchRecommendedAction());
  //   }
  // }, [dispatch, recommended]);

  return {
    languages,
    recommended,
    languageSelected,
    setLanguageSelected,
  };
};

export default useExplore;
