import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackOutline,
  arrowBackSharp,
  chatbubbleEllipsesOutline,
  colorPaletteOutline,
  easelOutline,
  libraryOutline,
  micOffOutline,
  micOutline,
  recordingOutline,
  shareOutline,
  stopCircleOutline,
  videocamOutline,
} from "ionicons/icons";
import { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";

import useClassroom from "../hooks/useClassroom";
import AlertCard from "../components/AlertCard";
import { classNames, handleError } from "../lib";
import moment from "moment";
import Video from "../components/Video";
import SketchPad from "../components/SketchPad";
import Material from "../components/Material";

const Classroom = memo(() => {
  const history = useHistory();
  const [showChat, setShowChat] = useState(false);
  const [showMain, setShowMain] = useState(1);

  const {
    data,
    error,
    loaded,
    user,
    tutor,
    startClass,
    myVideoRef,
    users,
    muteStream,
    myStream,
    myStreamMuted,
    screenData,
    shareScreen,
    stopRecordScreen,
    stopScreenShare,
    recordScreen,
    screenVideoRef,
    chunks,
    messageForm,
    materialsStatus,
    materialsError,
    materials,
  } = useClassroom();

  useEffect(() => {
    if (showMain == 2)
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1);
  }, [showMain, showChat]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon ios={arrowBackOutline} md={arrowBackSharp} />
            </IonButton>
          </IonButtons> */}
          <IonTitle>Classroom</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Classroom</IonTitle>
          </IonToolbar>
        </IonHeader>

        {!loaded ? <IonSpinner className="w-full" /> : null}
        {error ? (
          <AlertCard
            title={`Opps`}
            subtitle="Classroom"
            message={
              handleError(error) === "Class not found"
                ? handleError(error)
                : "Cannot access classroom. Please upgrade your subscription"
            }
            color="tertiary"
            noCloseBtn
          />
        ) : null}

        {data ? (
          data?.status === "Past" ? (
            <AlertCard
              title={`Opps`}
              subtitle={data.lesson.title}
              message={"Classroom has ended. Please watch recording."}
              color="tertiary"
              noCloseBtn
            />
          ) : data.status === "Pending" ? (
            <>
              <AlertCard
                title={`Opps`}
                subtitle={data.lesson.title}
                message={
                  "Class not started yet. The host/tutor has to start the class before you can be let in."
                }
                color="tertiary"
                noCloseBtn
              />
            </>
          ) : (
            <IonRow className="h-full">
              <IonCol
                size="12"
                sizeLg={showChat ? "9" : "12"}
                className="h-full"
              >
                <IonRow>
                  {/* tab area */}
                  <IonCol size="12">
                    {/* video stream area */}
                    <div
                      className={classNames(
                        showMain === 1 && "w-full h-full",
                        "w-0 h-0 overflow-hidden animate__animated animate__fadeIn",
                      )}
                    >
                      <div className="h-screen">
                        {/* {screenData ? ( */}
                        <div style={{ flexGrow: 1 }}>
                          {screenData?.username &&
                          screenData?.userId !== user?.id ? (
                            <p>{screenData.username} is presenting</p>
                          ) : null}
                          <video
                            ref={screenVideoRef}
                            playsInline
                            autoPlay
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        {/* ) : null} */}
                        {/* <video
                            controls
                            className="w-full h-full object-contain"
                            autoPlay
                            muted
                            loop
                          >
                            <source src={""} type="video/mp4" />
                            Sorry, your browser doesn&apos;t support embedded
                            videos.
                          </video> */}
                        <div className="flex items-center h-full">
                          <video
                            playsInline
                            ref={myVideoRef}
                            autoPlay
                            style={{
                              right: 0,
                              bottom: 0,
                              width: "100px",
                              height: "150px",
                              zIndex: 500,
                              borderRadius: 5,
                              objectFit: "cover",
                            }}
                          />
                          <div
                            style={{ overflowX: "scroll" }}
                            className="flex items-center ml-3"
                          >
                            {users.length
                              ? users.map((user: any) => (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <Video user={user} key={user.userId} />
                                ))
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center">
                        {myStream ? (
                          <IonButton onClick={muteStream} color="light">
                            <IonIcon
                              slot="icon-only"
                              icon={myStreamMuted ? micOffOutline : micOutline}
                            ></IonIcon>
                          </IonButton>
                        ) : null}

                        {user?.id === data.host.id ? (
                          screenData ? (
                            screenData?.userId === user?.id ? (
                              <IonButton onClick={stopScreenShare}>
                                <IonIcon
                                  slot="icon-only"
                                  icon={stopCircleOutline}
                                  color="secondary"
                                />
                              </IonButton>
                            ) : null
                          ) : (
                            <IonButton onClick={shareScreen}>
                              <IonIcon
                                slot="icon-only"
                                icon={shareOutline}
                                color="secondary"
                              />
                            </IonButton>
                          )
                        ) : null}
                        {/* {user?.id === data.host.id ? (
                          chunks.length ? (
                            <IonButton onClick={stopRecordScreen}>
                              <IonIcon
                                icon={stopCircleOutline}
                                color="danger"
                              />
                            </IonButton>
                          ) : (
                            <IonButton onClick={recordScreen}>
                              <IonIcon
                                icon={recordingOutline}
                                color="secondary"
                              />
                            </IonButton>
                          )
                        ) : null} */}
                      </div>
                    </div>

                    {/* whiteboard area */}
                    <div
                      className={classNames(
                        showMain === 2 && "w-full h-full",
                        "w-0 h-0 overflow-hidden animate__animated animate__fadeIn",
                      )}
                    >
                      <div className="relative  overflow-hidden">
                        <SketchPad />
                      </div>
                    </div>

                    {/* material area */}
                    {showMain === 3 && (
                      <div className="animate__animated animate__fadeIn overflow-hidden">
                        {materialsStatus === "loading" ? (
                          <IonSpinner className="w-full" />
                        ) : null}
                        {materialsStatus === "error" && materialsError ? (
                          <AlertCard
                            title={`Opps`}
                            subtitle="Materials"
                            message={handleError(materialsError)}
                            color="tertiary"
                            noCloseBtn
                          />
                        ) : null}

                        {/* {materialsStatus === "success" && !materials?.length ? (
                          <AlertCard
                            title={`Opps`}
                            subtitle="Materials"
                            message={"No materials"}
                            color="tertiary"
                            noCloseBtn
                          />
                        ) : null} */}

                        {materials?.length ? (
                          <Material materials={materials}/>
                        ) : null}
                      </div>
                    )}
                  </IonCol>

                  {/* tab buttons */}
                  <IonCol size="12" className="classroom-menu-btns">
                    <div className="flex flex-wrap items-center justify-start">
                      <>
                        <IonButton
                          onClick={() => setShowMain(1)}
                          color={showMain == 1 ? "primary" : "light"}
                          className="mr-8"
                        >
                          <IonIcon
                            slot="start"
                            icon={videocamOutline}
                          ></IonIcon>
                          Video stream
                        </IonButton>

                        <IonButton
                          onClick={() => setShowMain(2)}
                          color={showMain == 2 ? "primary" : "light"}
                          className="mr-8"
                        >
                          <IonIcon slot="start" icon={easelOutline}></IonIcon>
                          Whiteboard
                        </IonButton>

                        <IonButton
                          onClick={() => setShowMain(3)}
                          color={showMain == 3 ? "primary" : "light"}
                          className="mr-8"
                        >
                          <IonIcon slot="start" icon={libraryOutline}></IonIcon>
                          Material
                        </IonButton>
                      </>

                      <IonButton
                        onClick={() => setShowChat(!showChat)}
                        color={showChat ? "primary" : "light"}
                        className="mr-8"
                      >
                        <IonIcon
                          slot="start"
                          icon={chatbubbleEllipsesOutline}
                        ></IonIcon>
                        {showChat ? "Hide Chatbox" : "Open Chatbox"}
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCol>

              {showChat ? (
                <IonCol size="12" sizeLg="3" className="chatbox">
                  <div
                    className="chatbox-overlay"
                    onClick={() => setShowChat(!showChat)}
                  ></div>
                  <form
                    className="chatbox-content animate__animated animate__bounceIn"
                    onSubmit={messageForm.handleSubmit}
                  >
                    <div className="chatbox-msg">
                      {data?.chats
                        ? data?.chats.map((chat, i) => (
                            <div key={i} className={classNames(user?.id == chat.userId ? "chatbox-msg-user" : "chatbox-msg-others", "chatbox-chats")}>
                              <p>{chat.fullname}</p>
                              <p>{chat.message}</p>
                              <p>{moment(chat.createdAt).format("HH:mm")}</p>
                            </div>
                          ))
                        : null}
                    </div>
                    <div className="chatbox-input">
                      <textarea
                        name="message"
                        id=""
                        value={messageForm.values.message}
                        onChange={messageForm.handleChange}
                        className={`w-full ${
                          messageForm.touched.message &&
                          messageForm.errors.message
                            ? "border border-red-500"
                            : ""
                        }`}
                        placeholder="Enter message..."
                        autoFocus
                      ></textarea>
                      <IonButton
                        type="submit"
                        disabled={messageForm.isSubmitting}
                      >
                        <IonIcon
                          slot="start"
                          icon={chatbubbleEllipsesOutline}
                        ></IonIcon>
                        {messageForm.isSubmitting ? "..." : "Send"}
                      </IonButton>
                    </div>
                  </form>
                </IonCol>
              ) : null}
            </IonRow>
          )
        ) : null}
      </IonContent>
    </IonPage>
  );
});

export default Classroom;
