import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../lib";
import schedulersService from "../../services/schedulers.service";
import { IMaterials } from "../../types";

export const getMaterialsAction = createAsyncThunk<IMaterials[], string[]>(
  "materials/getMaterialsAction",
  async (ids, { rejectWithValue }) => {
    try {
      return await schedulersService.getScheduleMaterials(ids);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);
