import { useEffect } from "react";
import { Network } from "@capacitor/network";
import Auth from "../classes/Auth";
import { getToastOptions } from "../lib";
import { selectUser, setAuth, setTutor } from "../store/slices/authSlice";
import useGeneral from "./useGeneral";
import moment from "moment";
import { useIonToast } from "@ionic/react";
import { useAppSelector } from "./useDispatchSelector";
import tutorService from "../services/tutor.service";
import {
  loadSubscriptions,
  selectSubscriptions,
} from "../store/slices/subscriptionSlice";
import subscriptionsService from "../services/subscriptions.service";

const useAuth = () => {
  const [present] = useIonToast();
  const { dispatch } = useGeneral();
  const { user } = useAppSelector(selectUser);
  const { subscriptions } = useAppSelector(selectSubscriptions);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        // on initial load check network status
        const { connected } = await Network.getStatus();
        if (!connected)
          throw new Error(
            "Your device is currently offline, no internet connection",
          );

        // Authenticate the user
        const auth = new Auth();
        auth.authenticateUser((user: any, error: any) => {
          if (error) throw error;
          // set user in redux
          if (user?.createdAt) {
            user = {
              ...user,
              createdAt: moment(user?.createdAt.toDate()).fromNow(),
            };
          }
          dispatch(setAuth(user));

          // Luisten for network changes
          Network.addListener("networkStatusChange", ({ connected }) => {
            if (!connected) {
              present(
                getToastOptions(
                  "Your device is currently offline, no internet connection",
                ),
              );
            }
          });
        });
      } catch (error: any) {
        present(getToastOptions(error.message));
      }
    };

    authenticateUser();
    return () => {
      Network.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    let sub: any = null;
    if (user && !sub) {
      sub = tutorService.watchTutor(user?.id!, (data: any) =>
        dispatch(setTutor(data)),
      );
    }

    if (!user && sub) {
      sub();
      dispatch(setTutor(null));
    }

    return () => {
      if (sub) {
        sub();
        dispatch(setTutor(null));
      }
    };
  }, [dispatch, user]);

  useEffect(() => {
    let sub: any = null;
    if (user && !subscriptions.length && !sub) {
      sub = subscriptionsService.watchSubscriptions((data) =>
        dispatch(loadSubscriptions(data)),
      );
    }

    if (!user && sub) {
      sub();
    }

    return () => {
      if (sub) {
        sub();
      }
    };
  }, [dispatch, subscriptions.length, user]);
};

export default useAuth;
