import { IonText } from "@ionic/react";

const ErrorText = (props:any) => {
  let errorEl;
  if (props.touched && props.errors) {
    errorEl = (
      <IonText color="danger">
        <p className="ion-padding-start">{props.errors}</p>
      </IonText>
    );
  } else {
    errorEl = <></>;
  }
  return errorEl;
};
export default ErrorText;
