import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "..";

// Define a type for the slice state
interface SplitState {
  visible: boolean;
}

// Define the initial state using that type
const initialState: SplitState = {
  visible: false,
};

export const splitSlice = createSlice({
  name: "split",
  initialState,
  reducers: {
    setSplit: (state, action: PayloadAction<any>) => {
      state.visible = action.payload;
    },
  },
});

export const { setSplit } = splitSlice.actions;
export const selectSplit = (state: RootState) => state.split;

const splitReducer = splitSlice.reducer;
export default splitReducer;
