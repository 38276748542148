import { PaymentSheetEventsEnum } from "@capacitor-community/stripe";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonSlide,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { diamondOutline, checkmarkCircle } from "ionicons/icons";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../hooks/useDispatchSelector";
import { getLoaderOptions, getToastOptions, handleError } from "../lib";
import paymentService from "../services/payment.service";
import subscriptionsService from "../services/subscriptions.service";
import { selectUser } from "../store/slices/authSlice";
import { ISubscription } from "../types";

interface Props {
  subscription: ISubscription;
  renewal: boolean;
}

const Subscription: React.FC<Props> = ({ subscription, renewal }) => {
  const { stripe } = useCapacitorStripe();
  const { user } = useAppSelector(selectUser);
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [presentLoader, dismissLoader] = useIonLoading();
  const [features, setFeatures] = useState<string[]>([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const data = await subscriptionsService.fetchFeatures(
          subscription.features
        );
        setFeatures(data);
      } catch (error) {
        presentToast(getToastOptions(handleError(error)));
      }
    };
    fetchFeatures();
  }, [presentToast, subscription.features]);

  const createPayment = useCallback(async () => {
    presentLoader(getLoaderOptions());
    try {
      const res = await paymentService.create(subscription.id, renewal);
      await stripe.createPaymentSheet({
        paymentIntentClientSecret: res.url,
        merchantDisplayName: "Vernacular Academy",
      });
      dismissLoader();
      const result = await stripe.presentPaymentSheet();
      if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
        presentToast(getToastOptions("Processing Payment"));
      }
    } catch (error) {
      presentToast(getToastOptions(handleError(error)));
      dismissLoader();
    }
  }, [
    dismissLoader,
    presentLoader,
    presentToast,
    renewal,
    stripe,
    subscription.id,
  ]);

  const checkSubscription = useCallback(() => {
    if (
      user?.subscription.expiresIn &&
      moment().isBefore(user?.subscription?.expiresIn?.toDate())
    ) {
      presentAlert({
        header: "Subscription",
        subHeader: "You have an active subscription.",
        message:
          "Your previous subscription is still active. Making payment for a new subscription will override your old subscription without rollover.",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "OK",
            role: "Continue",
            handler: createPayment,
          },
        ],
      });
    } else {
      createPayment();
    }
  }, [createPayment, presentAlert, user?.subscription.expiresIn]);

  const price = useMemo(() => {
    if (!subscription.discount) {
      return renewal ? subscription.price * 12 : subscription.price;
    }

    if (subscription.discountApplies === 12) {
      return renewal
        ? (subscription.price -
            (subscription.discount / 100) * subscription.price) *
            12
        : subscription.price;
    }

    return renewal
      ? (subscription.price -
          (subscription.discount / 100) * subscription.price) *
          12
      : subscription.price - (subscription.discount / 100) * subscription.price;
  }, [
    renewal,
    subscription.discount,
    subscription.discountApplies,
    subscription.price,
  ]);

  return (
    <IonSlide>
      <IonCard className="pricing-card">
        <IonCardContent className="ion-text-start">
          <h3 className="ion-text-capitalize">
            <IonIcon
              icon={diamondOutline}
              className={`icon-bg bg-gradient-to-bl ${subscription.color}`}
            />{" "}
            <strong
              className={`text-bg bg-gradient-to-bl ${subscription.color}`}
            >
              {subscription.name}
            </strong>
          </h3>
          <h4>What You'll Get</h4>
          {features
            ? features.map((feature) => (
                <div key={feature}>
                  <p>
                    <IonIcon icon={checkmarkCircle} />
                    <span>{feature}</span>
                  </p>
                </div>
              ))
            : null}
          <hr />
          <h3>
            <strong>${price}</strong>
            <small>/{renewal ? "Year" : "Month"}</small>
          </h3>
          <div className="absolute bottom-20 w-full">
            <button
              type="button"
              className={`sub-button ion-text-uppercase  bg-gradient-to-bl ${subscription.color}`}
              onClick={checkSubscription}
            >
              Choose
            </button>
          </div>
        </IonCardContent>
      </IonCard>
    </IonSlide>
  );
};

export default Subscription;
