import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ILanguage } from "../../types";
import { fetchLanguagesAction } from "../actions/languages.actions";

// Define a type for the slice state
export interface ILanguagesState {
  languages: ILanguage[];
}

// Define the initial state using that type
const initialState: ILanguagesState = {
  languages: [],
};

export const languagesSlices = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchLanguagesAction.fulfilled, (state, action) => {
      state.languages = action.payload;
    }),
});

export const selectLanguages = (state: RootState) => state.languages;

const languagesReducer = languagesSlices.reducer;
export default languagesReducer;
