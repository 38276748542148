import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import Peer from "peerjs";
import User from "../classes/User";
import { baseURL, connectConfig, handleError, toast } from "../lib";
import useGeneral from "./useGeneral";
import { useParams } from "react-router";

const useLive = () => {
  const { auth } = useGeneral();
  const { roomId } = useParams<{ roomId: string }>();
  const user = new User(auth?.user?.id!);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [users, setUsers] = useState<
    Array<{ username: string; userId: string; socketId: string }>
  >([]);

  const [messages, setMessages] = useState<
    Array<{ message: string; from: string }>
  >([]);

  const [newMessageCount, setNewMessageCount] = useState<number>(0);

  const [myStream, setMyStream] = useState<any>();
  const [myStreamMuted, setMyStreamMuted] = useState<boolean>(false);
  const [myPeer, setMyPeer] = useState<any>();
  const [screenPeer, setScreenPeer] = useState<any>();
  const [screenData, setScreenData] = useState<any>(null);
  const myVideoRef = useRef<any>();
  const screenVideoRef = useRef<any>();
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);
  const [chunks, setChunks] = useState<any[]>([]);

  const recordScreen = () => {
    mediaRecorder.start(100);
  };

  const stopRecordScreen = () => {
    mediaRecorder.stop();
    let blob = new Blob(chunks, {
      type: "video/webm",
    });
    let url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = "test";
    a.click();
    window.URL.revokeObjectURL(url);
    setChunks([]);
  };

  const shareScreen = async () => {
    try {
      const peer = new Peer("1234", connectConfig);
      setScreenPeer(peer);

      peer.on("open", async () => {
        const captureStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });

        let options = {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
          mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"',
        };

        if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
          options = { ...options, mimeType: "video/webm; codecs=vp9" };
        } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
          options = { ...options, mimeType: "video/webm; codecs=vp8" };
        } else {
          toast(
            "Your device does not have the capabilities to record screen",
            "Screen Recorder",
          );
        }

        if (MediaRecorder.isTypeSupported(options.mimeType)) {
          const mR = new MediaRecorder(captureStream, options);
          setMediaRecorder(mR);
          mR.ondataavailable = (event) => {
            console.log(event.data);
            if (event.data.size > 0) {
              setChunks((prevState) => [...prevState, event.data]);
            }
          };
        }

        socket?.emit("screenShare", {
          roomId,
          username: auth?.user?.fullname,
          userId: auth?.user?.id,
        });

        setScreenData({
          roomId,
          username: auth?.user?.fullname,
          userId: auth?.user?.id,
          stream: captureStream,
        });
        screenVideoRef.current.srcObject = captureStream;

        users.forEach((user) => {
          peer.call(user.userId, captureStream);
        });

        socket?.on("reconnect", ({ userId, socketId }) => {
          socket?.emit("screenShareReconnect", {
            username: auth?.user?.fullname,
            userId: auth?.user?.id,
            to: socketId,
          });
          peer.call(userId, captureStream);
        });
      });
    } catch (error) {
      toast(handleError(error), "Share Screen");
    }
  };

  const stopScreenShare = () => {
    const tracks = screenVideoRef.current.srcObject.getTracks();
    tracks.forEach((track: any) => track.stop());
    setScreenData(null);
    socket?.emit("screenShareStop");
    screenPeer.disconnect();
    setScreenPeer(null);
  };

  const muteStream = () => {
    const enabled = myStream.getAudioTracks()[0].enabled;
    if (enabled) {
      myStream.getAudioTracks()[0].enabled = false;
      setMyStreamMuted(true);
    } else {
      myStream.getAudioTracks()[0].enabled = true;
      setMyStreamMuted(false);
    }
  };

  useEffect(() => {
    const connectToServer = async () => {
      try {
        if (navigator.mediaDevices === undefined)
          throw new Error("You browser does not support video call");

        const token = await user.getAuthToken();
        if (!token) return;
        const { data } = await axios({
          method: "GET",
          url: "/",
          baseURL,
          headers: {
            Authorization: token,
          },
        });

        const s = io(`${baseURL}`, {
          auth: {
            token,
          },
        });
        setSocket(s);

        const mS = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            sampleRate: 44100,
          },
          video: {
            facingMode: "user",
          },
        });
        setMyStream(mS);
        myVideoRef.current.srcObject = mS;
        myVideoRef.current.muted = true;

        const peer = new Peer(auth?.user?.id || "", connectConfig);

        setMyPeer(peer);

        console.log(myPeer);

        peer.on("open", () => {
          peer.on("call", (call) => {
            if (call.peer === "1234") {
              call?.answer(myStream);
              call.on("stream", (stream) => {
                setScreenData((prevState: any) => ({ ...prevState, stream }));
                screenVideoRef.current.srcObject = stream;
              });
            } else {
              call.answer(mS);
              call.on("stream", (stream) => {
                setUsers((prevUsers) =>
                  prevUsers.find((u) => u.userId === call.peer)
                    ? prevUsers.map((u) =>
                        u.userId === call.peer ? { ...u, stream } : u,
                      )
                    : [...prevUsers, { ...data, stream }],
                );
              });
            }
          });

          peer.on("error", (error) => {
            console.log(error);
          });

          s.emit("join-room", {
            roomId,
            userId: auth.user?.id,
            username: auth.user?.fullname,
          });

          s.on("user-joined", (data) => {
            setUsers((prevUsers) => [...prevUsers, { ...data }]);
            s.emit("acknowledgement", {
              userId: auth.user?.id,
              username: auth.user?.fullname,
              socketId: s.id,
              newUserSocketId: data.socketId,
            });

            const call = peer.call(data.userId, mS);
            call.on("stream", (stream) => {
              setUsers((prevUsers) =>
                prevUsers.find((u) => u.userId === data.userId)
                  ? prevUsers.map((u) =>
                      u.userId === data.userId ? { ...u, stream } : u,
                    )
                  : [...prevUsers, { ...data, stream }],
              );
            });
          });

          s.on("acknowledgement", (data) => {
            setUsers((prevUsers) => [...prevUsers, { ...data }]);
          });

          s.on("screenShare", (data) => {
            setScreenData({ ...data });
          });

          s.on("screenShareStop", () => {
            const tracks = screenVideoRef.current.srcObject.getTracks();
            tracks.forEach((track: any) => track.stop());
            setScreenData(null);
          });

          s.on("user-disconnect", (id) => {
            setUsers((prevUsers) =>
              prevUsers.filter(({ userId }) => userId !== id),
            );
          });

          s.on("message", (data) => {
            setMessages((prevState) => [...prevState, data]);
            setNewMessageCount((prevState) => prevState + 1);
          });
        });
      } catch (error) {
        toast(handleError(error), "Connection");
      }
    };
    connectToServer();
  }, []);

  return {
    messages,
    socket,
    roomId,
    isOpen,
    setIsOpen,
    setMessages,
    users,
    myVideoRef,
    screenData,
    screenVideoRef,
    shareScreen,
    auth,
    newMessageCount,
    setNewMessageCount,
    stopScreenShare,
    myStreamMuted,
    muteStream,
    recordScreen,
    stopRecordScreen,
    chunks,
  };
};

export default useLive;
