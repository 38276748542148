import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import {
  cardOutline,
  cardSharp,
  gameControllerOutline,
  gameControllerSharp,
  globeOutline,
  globeSharp,
  personOutline,
  personSharp,
} from "ionicons/icons";

const Toolbar = (props: any) =>
  props.pageURL ? (
    <IonFooter className="mobile-toolbar">
       {window.innerWidth < 992 ? (
      <IonToolbar color="primary">
        <IonButtons>
          {[
            {
              name: "Explore",
              href: "explore",
              ios: globeOutline,
              md: globeSharp,
            },
            {
              name: "Games",
              href: "games",
              ios: gameControllerOutline,
              md: gameControllerSharp,
            },
            {
              name: "Subscriptions",
              href: "subscriptions",
              ios: cardOutline,
              md: cardSharp,
            },
            {
              name: "Profile",
              href: "profile",
              ios: personOutline,
              md: personSharp,
            },
          ].map((link) => (
            <IonButton
              key={link.href}
              routerLink={`/${link.href}`}
              className={`${
                link.href === (props.pageURL).split("/")[1] ? "" : "opacity-50"
              } toolbar-container`}
            >
              <div className="toolbar-content">
                <IonIcon ios={link.ios} md={link.md} />
                <IonLabel>{link.name}</IonLabel>
              </div>
            </IonButton>
          ))}
        </IonButtons>
      </IonToolbar>): null}
    </IonFooter>
  ) : (
    <></>
  );

export default Toolbar;
