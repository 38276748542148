import { auth } from "../firebase.config";
import { IUser as UserData } from "../store/slices/authSlice";
import { IUser } from "../types/user";
import Database from "./Database";
import Storage from "./Storage";

class User extends Database implements IUser {
  private collection: string = "users";
  private storage = new Storage();
  constructor(private id: string) {
    super();
  }
  getProfile: () => Promise<UserData> = async () => {
    const profile = await this.getDocument(this.collection, this.id);
    return profile;
  };
  updateProfile: <T>(data: T) => Promise<void> = async (data) => {
    if (!data) throw new Error("Missing Argument");
    await this.updateDocument(this.collection, this.id, data);
  };

  changeProfilePicture: (dataUrl: string) => Promise<void> = async (
    dataUrl,
  ) => {
    const data = await this.storage.uploadFile(dataUrl, `profile/${this.id}`, {
      customMetadata: {
        owner: this.id,
      },
    });

    await this.updateProfile<{ profilePicture: { url: string; path: string } }>(
      { profilePicture: { ...data } },
    );
  };

  public createProfile: <T>(data: T) => Promise<void> = async (data) => {
    if (!data) throw new Error("Missing Argument");
    await this.createDoc(this.collection, data, this.id);
  };

  public watchProfile: (cb: any) => void = (cb) => {
    this.watchDoc(this.collection, this.id, cb);
  };

  public getAuthToken: () => Promise<string | undefined> = async () => {
    const token = await auth.currentUser?.getIdToken(true);
    return token;
  };
}

export default User;
