import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ILesson, IMaterials } from "../../types";
import { fetchLessonsAction } from "../actions/lessons.actions";
import { getMaterialsAction } from "../actions/materials.actions";

// Define a type for the slice state
export interface IInitialState {
  loading: boolean;
  materials: IMaterials[];
}

// Define the initial state using that type
const initialState: IInitialState = {
  loading: false,
  materials: [],
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(getMaterialsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.materials = action.payload;
    }),
});

export const selectLessons = (state: RootState) => state.materials;

const materialsReducer = materialsSlice.reducer;
export default materialsReducer;
