import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Notifications from "../components/Notifications";
import Toolbar from "../components/Toolbar";

import {
  cardOutline,
  checkbox,
  checkboxOutline,
  clipboardOutline,
  copyOutline,
  logoUsd,
  textOutline,
  timeOutline,
} from "ionicons/icons";
import { SVGStripe } from "../components/UIComponents";
import Modal from "../components/Modal";
import AlertCard from "../components/AlertCard";
import { useAppDispatch, useAppSelector } from "../hooks/useDispatchSelector";
import {
  loadTransactions,
  selectTransactions,
} from "../store/slices/transactionsSlice";
import { selectUser } from "../store/slices/authSlice";
import { getToastOptions, handleError } from "../lib";
import transactionsService from "../services/transaction.service";
import moment from "moment";
import { Clipboard } from "@capacitor/clipboard";
import { Toast } from "@capacitor/toast";
import { Timestamp } from "firebase/firestore";

interface PaymentDetail {
  id: string;
  createdAt: Timestamp;
  amount: any;
  method: number;
  status: number;
  docId: string;
  description: string;
  type: string;
}

const Payments: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const { transactions, lastVisible, loaded, more } =
    useAppSelector(selectTransactions);
  const [spinner, setSpinner] = useState(false);
  const [presentToast] = useIonToast();

  const [modal, setModal] = useState(false);
  const [pd, setPaymentDetail] = useState<PaymentDetail | null>(null);

  const fetchTransaction = useCallback(async () => {
    try {
      setSpinner(true);
      const data = await transactionsService.fetchTransactions(
        user?.id!,
        lastVisible
      );
      dispatch(loadTransactions(data));
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      presentToast(getToastOptions(handleError(error)));
    }
  }, [dispatch, lastVisible, presentToast, user?.id]);

  useEffect(() => {
    if (!loaded) {
      fetchTransaction();
    }
  }, [fetchTransaction, loaded]);

  const handleModal = () => {
    setModal(!modal);
  };

  const copyToClipboard = async (string: string | undefined, title: string | undefined) => {
    await Clipboard.write({ string });

    await Toast.show({
      text: `${title} copied to clipboard!`,
    });
  };

  const openDetails = (data: any) => {
    setPaymentDetail(data);
    handleModal();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <Notifications />
          </IonButtons>
          <IonTitle>Payments History</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Payments History</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* Load payments history here */}
        {loaded && !transactions.length ? (
            <AlertCard
              title="No Payments"
              subtitle="History Here!"
              message="Please proceed to Pricing & Plans page to purchase a subscription."
              color="success"
              noCloseBtn
            />
        ) : (
          <IonCard>
            <IonCardContent className="ion-no-padding">
              <IonRow className="items-center ion-padding">
                <IonCol size="4" sizeMd="3" sizeLg="2" className="lg-show">
                  <IonText color="primary">
                    <small>Order ID</small>
                  </IonText>
                </IonCol>
                <IonCol size="4" sizeMd="3" sizeLg="2" className="lg-show">
                  <IonText color="primary">
                    <small>Type</small>
                  </IonText>
                </IonCol>
                <IonCol size="4" sizeMd="3" sizeLg="2" className="">
                  <IonText color="primary">
                    <small>Payment Date</small>
                  </IonText>
                </IonCol>
                <IonCol size="4" sizeMd="4" sizeLg="2" className="md-show">
                  <IonText color="primary">
                    <small>Item</small>
                  </IonText>
                </IonCol>
                <IonCol size="4" sizeMd="3" sizeLg="1" className="text-center">
                  <IonText color="primary">
                    <small>Amount</small>
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  sizeMd="3"
                  sizeLg="1"
                  className="text-center lg-show"
                >
                  <IonText color="primary">
                    <small>Method</small>
                  </IonText>
                </IonCol>
                <IonCol
                  size="4"
                  sizeMd="3"
                  sizeLg="2"
                  className="text-center lg-show"
                >
                  <IonText color="primary">
                    <small>Status</small>
                  </IonText>
                </IonCol>
                <IonCol size="4" sizeMd="2" className="lg-hide">
                  <IonText color="primary">
                    <small>Action</small>
                  </IonText>
                </IonCol>
              </IonRow>

              <div className="divider m-0">&nbsp;</div>

              <div className="payments-tbl">
                {transactions.map(
                  ({
                    id,
                    createdAt,
                    amount,
                    metadata: { method, status, docId, description, type },
                  }) => (
                    <IonRow className="items-center ion-padding" key={id}>
                      <IonCol
                        size="4"
                        sizeMd="3"
                        sizeLg="2"
                        className="lg-show"
                      >
                        <IonButton
                          expand="block"
                          color="dark"
                          fill="outline"
                          size="small"
                          onClick={() => copyToClipboard(id, "Order ID")}
                        >
                          <small className="w-full overflow-hidden nowrap text-ellipsis">
                            <IonIcon icon={copyOutline} /> {id}
                          </small>
                        </IonButton>
                      </IonCol>
                      <IonCol
                        size="4"
                        sizeMd="3"
                        sizeLg="2"
                        className="lg-show"
                      >
                        <IonText color="dark">{type || "Subscription"}</IonText>
                      </IonCol>
                      <IonCol size="4" sizeMd="3" sizeLg="2" className="">
                        <IonText color="dark">
                          {moment(createdAt?.toDate()).format("DD-MM-YYYY")}
                        </IonText>
                      </IonCol>
                      <IonCol
                        size="4"
                        sizeMd="4"
                        sizeLg="2"
                        className="md-show"
                      >
                        <IonText color="dark">{description}</IonText>
                      </IonCol>
                      <IonCol
                        size="4"
                        sizeMd="3"
                        sizeLg="1"
                        className="text-center"
                      >
                        <IonText color="dark">$ {amount / 100}</IonText>
                      </IonCol>
                      <IonCol
                        size="4"
                        sizeMd="3"
                        sizeLg="1"
                        className="text-center lg-show"
                      >
                        <IonText color="dark">
                          {method === 1 ? (
                            <SVGStripe />
                          ) : (
                            <IonIcon icon={logoUsd} />
                          )}
                        </IonText>
                      </IonCol>
                      <IonCol
                        size="4"
                        sizeMd="3"
                        sizeLg="2"
                        className="text-center lg-show"
                      >
                        <IonText color="dark">
                          {status === 1 ? (
                            <IonBadge color="warning">Pending</IonBadge>
                          ) : status === 2 ? (
                            <IonBadge color="success">Success</IonBadge>
                          ) : (
                            <IonBadge color="danger">Failed</IonBadge>
                          )}
                        </IonText>
                      </IonCol>
                      <IonCol size="4" sizeMd="2" className="lg-hide">
                        <IonButton
                          fill="outline"
                          color="medium"
                          className="ion-text-capitalize"
                          size="small"
                          onClick={() =>
                            openDetails({
                              id,
                              createdAt,
                              amount,
                              method,
                              status,
                              docId,
                              description,
                              type,
                            })
                          }
                        >
                          Details
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  )
                )}
              </div>
              <IonFooter>
                {spinner ? <IonSpinner /> : null}
                {loaded && more ? (
                  <IonButton
                    expand="block"
                    fill="clear"
                    color="medium"
                    onClick={() => fetchTransaction}
                  >
                    Load More
                  </IonButton>
                ) : null}
              </IonFooter>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
      <Toolbar pageURL={location.pathname} />
      <Modal showModal={modal} handleModal={handleModal} title="Payment">
        <div className="ion-padding">
          <AlertCard
            color={
              pd?.status === 1
                ? "warning"
                : pd?.status === 2
                ? "success"
                : "danger"
            }
            title={
              pd?.status === 1
                ? "Pending!"
                : pd?.status === 2
                ? "Successful!"
                : "Failed!"
            }
            subtitle="Payment Details"
            message={`${
              pd?.status === 1
                ? "Please wait while payment is processing."
                : pd?.status === 2
                ? "Payment was successful, see transaction details below."
                : "Payment failed, see transaction details below. If you require assistance please contact support@vernacularacademy.com"
            }`}
          />

          <IonCard className="m-0">
            <img src="/assets/img/patternbg.jpeg" alt="pattern"/>
            <IonCardHeader>
              <IonCardTitle>More Details</IonCardTitle>
              <IonCardSubtitle>swipe right to copy</IonCardSubtitle>
            </IonCardHeader>
            <IonList>
              {[
                {
                  name: "Order ID",
                  text: pd?.id,
                  icon: clipboardOutline,
                },
                {
                  name: "Timestamp",
                  text: moment(pd?.createdAt?.toDate()).format(
                    "DD-MM-YYYY | hh:mm"
                  ),
                  icon: timeOutline,
                },
                {
                  name: "Amount",
                  text: `$ ${pd?.amount / 100}`,
                  icon: cardOutline,
                },
                {
                  name: "Description",
                  text: pd?.description,
                  icon: textOutline,
                },
                {
                  name: "Payment Status",
                  text:
                    pd?.status === 1
                      ? "Pending!"
                      : pd?.status === 2
                      ? "Successful!"
                      : "Failed!",
                  icon: checkboxOutline,
                },
              ].map((item) => (
                <IonItemSliding key={item.name}>
                  <IonItem>
                    <div className="link-icon normal" slot="start">
                      <IonIcon icon={item.icon} color={item.name !== "Payment Status" ? "primary" : pd?.status === 1
                      ? "warning"
                      : pd?.status === 2
                      ? "success"
                      : "danger"} />
                    </div>
                    <IonLabel>
                      <h2>{item.name}</h2>
                      <p>{item.text}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItemOptions side="end">
                    <IonItemOption onClick={() => copyToClipboard(item.text, item.name)}>
                      <small className="w-full overflow-hidden nowrap text-ellipsis">
                        <IonIcon icon={copyOutline} /> Copy
                      </small>
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
            </IonList>
          </IonCard>
        </div>
      </Modal>
    </IonPage>
  );
};

export default Payments;
