import { lazy, Suspense } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./styles/variables.css";
import "./styles/CustomUtility.css";
import "animate.css";

/* Additional imports */
import LeftDrawer from "./components/LeftDrawer";
import Authentication from "./pages/Authentication";
import Onboarding from "./pages/Onboarding";
import Lesson from "./pages/Lesson";
import Classroom from "./pages/Classroom";
import Subscriptions from "./pages/Subscriptions";
import SplashScreen from "./components/SplashScreen";
import useGeneral from "./hooks/useGeneral";
import useAuth from "./hooks/useAuth";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import { setSplit } from "./store/slices/splitSlice";
import NotFound from "./pages/NotFound";
import Live from "./pages/Live";
import Explore from "./pages/Explore";
import Games from "./pages/Games";
import Profile from "./pages/Profile";
import TutorRegistration from "./pages/TutorRegistration";
import Payments from "./pages/Payments";
import TutorSchedule from "./pages/TutorSchedule";
import TutorClassroom from "./pages/TutorClassroom";
const TutorDashboard = lazy(() => import("./pages/TutorDashboard"));
const TutorProfile = lazy(() => import("./pages/TutorProfile"));

// Note: setupIonicReact must be called
setupIonicReact({});

const App: React.FC = () => {
  useAuth();
  const {
    auth,
    split: { visible },
    dispatch,
  } = useGeneral();

  if (!auth.loaded) {
    return <SplashScreen />;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane
          contentId="main"
          onIonSplitPaneVisible={(e) => dispatch(setSplit(e.detail.visible))}
        >
          <LeftDrawer />
          <IonRouterOutlet id="main">
            <Route exact path="/">
              {auth.user ? (
                <Redirect to="/explore" />
              ) : visible ? (
                <Redirect to="/authentication" />
              ) : (
                <Redirect to="/onboarding" />
              )}
            </Route>

            <PublicRoute
              path="/authentication/:tab?"
              Component={Authentication}
            />

            <PublicRoute path="/onboarding" Component={Onboarding} />
            <PublicRoute path="/not-found" Component={NotFound} />

            <PrivateRoute path="/explore/:courseID?" Component={Explore} />
            <PrivateRoute path="/games/:gameID?" Component={Games} />
            <PrivateRoute path="/profile" Component={Profile} />
            <PrivateRoute path="/payments" Component={Payments} />
            <PrivateRoute path="/lesson/:lessonID" Component={Lesson} />
            <PrivateRoute path="/classroom/:roomID" Component={Classroom} />
            <PrivateRoute path="/live/:roomId" Component={Live} />
            <PrivateRoute path="/subscriptions" Component={Subscriptions} />
            {auth.user?.role === "tutor" ? (
              <Suspense fallback={<SplashScreen />}>
                <PrivateRoute
                  path="/tutor-dashboard"
                  Component={TutorDashboard}
                />
                <PrivateRoute path="/tutor-profile" Component={TutorProfile} />
                <PrivateRoute path="/tutor-schedule" Component={TutorSchedule} />
                <PrivateRoute path="/tutor-classroom/:roomID" Component={TutorClassroom} />
              </Suspense>
            ) : (
              <Suspense fallback={<SplashScreen />}>
                <PrivateRoute
                  path="/tutor-registration"
                  Component={TutorRegistration}
                />
              </Suspense>
            )}
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
