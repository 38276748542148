import { FirestoreError, Unsubscribe } from "firebase/firestore";
import moment from "moment";
import { CollectionNames } from "../enums";
import {
  query,
  where,
  getDocs,
  db,
  collection,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  arrayUnion,
  getDoc,
  uploadBytesResumable,
  storage,
  ref,
  timestamp,
  documentId,
} from "../firebase.config";
import { IMaterials, ISchedule } from "../types";

const schedulersService = {
  getSchedulers: async (id: string, level: number) => {
    const q = query(
      collection(db, CollectionNames.SCHEDULERS),
      where("access.level", "<=", level),
      where("lesson.id", "==", id),
      where("status", "in", ["Pending", "Live"]),
      //   orderBy("createdAt", "desc"),
    );
    const snapshots = await getDocs(q);
    if (snapshots.empty) throw new Error("No Schedules at the moment");
    return snapshots.docs.map(
      (d) =>
        ({
          id: d.id,
          ...d.data(),
        } as ISchedule),
    );
  },

  attendSchedule: async (id: string, userId: string) => {
    await updateDoc(doc(db, CollectionNames.SCHEDULERS, id), {
      attendees: arrayUnion(userId),
    });
  },

  getScheduleById: async (id: string) => {
    const snapshot = await getDoc(doc(db, CollectionNames.SCHEDULERS, id));
    if (!snapshot.exists()) throw new Error("Class not found");
    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as ISchedule;
  },

  getHostSchedules: async (tutorId: string) => {
    const q = query(
      collection(db, CollectionNames.SCHEDULERS),
      where("host.id", "==", tutorId),
      where("status", "in", ["Pending", "Live"]),
      //   orderBy("createdAt", "desc"),
    );
    const snapshots = await getDocs(q);
    return snapshots.docs.map(
      (d) =>
        ({
          id: d.id,
          ...d.data(),
        } as ISchedule),
    );
  },

  getScheduleMaterials: async (ids: string[]) => {
    const len = ids.length;
    let page = Math.ceil(len / 10);

    const promise = [];

    while (page > 0) {
      if (ids.length > 10) {
        const q = query(
          collection(db, CollectionNames.MATERIALS),
          where(documentId(), "in", ids.splice(0, 10)),
          // orderBy("learningObjectives", "asc"),
        );

        promise.push(getDocs(q));
      } else {
        const q = query(
          collection(db, CollectionNames.MATERIALS),
          where(documentId(), "in", ids.splice(0)),
          // orderBy("learningObjectives", "asc"),
        );

        promise.push(getDocs(q));
      }
      page--;
    }

    // const q = query(
    //   collection(db, CollectionNames.MATERIALS),
    //   where(documentId(), "in", ids),
    //   // orderBy("learningObjectives", "asc"),
    // );

    // const snapshots = await getDocs(q);
    const res = await Promise.all(promise);

    const materials = res.flatMap((snapshots) =>
      snapshots.docs.map(
        (d) =>
          ({
            id: d.id,
            ...d.data(),
          } as IMaterials),
      ),
    );

    return materials;

    // return snapshots.docs.map(
    //   (d) =>
    //     ({
    //       id: d.id,
    //       ...d.data(),
    //     } as IMaterials),
    // );
  },

  uploadRecording: (
    blob: Blob,
    metadata: {
      contentType: string;
      lessonId: string;
      scheduleId: string;
      accessLevel: number;
    },
  ) => {
    const storageref = ref(
      storage,
      `recordings/${metadata.lessonId}/${metadata.scheduleId}/12345.webm`,
    );
    uploadBytesResumable(storageref, blob, {
      contentType: metadata.contentType,
      customMetadata: {
        lessonId: metadata.lessonId,
        scheduleId: metadata.scheduleId,
        accessLevel: `${metadata.accessLevel}`,
      },
    }).catch((err) => console.log(err));
  },

  watchClassRoom: (
    roomId: string,
    handleSuccess: (data: ISchedule) => void,
    handleErr: (err: any) => void,
  ) => {
    onSnapshot(
      doc(db, CollectionNames.SCHEDULERS, roomId),
      (snapshot) => {
        if (!snapshot.exists()) {
          throw handleErr(new Error("Class not found"));
        }

        handleSuccess({
          id: snapshot.id,
          ...snapshot.data(),
        } as ISchedule);
      },
      (err) => handleErr(err),
    );
  },

  sendMessage: async (
    scheduleId: string,
    data: { message: string; userId: string; fullname: string },
  ) => {
    await updateDoc(doc(db, CollectionNames.SCHEDULERS, scheduleId), {
      chats: arrayUnion({
        ...data,
        createdAt: +moment(),
      }),
    });
  },
};

export default schedulersService;
