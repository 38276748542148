import React from "react";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { store } from "./store";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { CapacitorStripeProvider } from "@capacitor-community/stripe/dist/esm/react/provider";
import SplashScreen from "./components/SplashScreen";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <CapacitorStripeProvider
      publishableKey="pk_test_51LGtAkLdd9VYULKraiTwxGbhAZSvnSVW5FUIN9JrXgV2PgzJqwwwTcqieLqiXbHZ04kjQBf1RHyPZL9DRPuu7HRo00rnNr71T9"
      fallback={<SplashScreen />}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </CapacitorStripeProvider>
  </React.StrictMode>,
  // document.getElementById("root"),
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
