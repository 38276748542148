export enum CollectionNames {
  LANGUAGES = "languages",
  LESSONS = "lessons",
  TUTORS = "tutors",
  SUBSCRIPTIONS = "subscriptions",
  FEATURES = "features",
  TRANSACTIONS = "transactions",
  SCHEDULERS = "schedulers",
  MATERIALS = "materials",
}

export enum CollectionLimits {
  RECOMMENDED = 10,
}
