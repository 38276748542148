import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "../styles/Authentication.css";
import Signup from "../components/Signup";
import Verify from "../components/Verify";
import Reset from "../components/Reset";
import Login from "../components/Login";
import { Redirect, useParams } from "react-router";
import useGeneral from "../hooks/useGeneral";

const Authentication: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();

  const {
    split: { visible },
    auth: { user },
  } = useGeneral();

  return user ? (
    <Redirect to="/" />
  ) : (
    <IonPage id="authentication-page">
      <img
        src={
          !visible ? "assets/icon/icon-white.svg" : "assets/icon/icon-212.png"
        }
        alt="logo"
        className="absolute right-16 top-16 h-50 z-10"
      />
      <IonContent
        fullscreen
        className={
          !visible ? "auth-mobile ion-padding" : "auth-desktop ion-padding"
        }
      >
        {(tab === "login" || tab === undefined) && <Login />}
        {tab === "signup" && <Signup />}
        {tab === "verify" && <Verify />}
        {tab === "reset" && <Reset />}
      </IonContent>
    </IonPage>
  );
};

export default Authentication;
