import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { handleError } from "../../lib";
import schedulersService from "../../services/schedulers.service";
import { ISchedule } from "../../types";

export const getSchedulersAction = createAsyncThunk<ISchedule[], string>(
  "schedulers/getSchedulersAction",
  async (id, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const level = state.auth.user?.subscription.accessLevel!;
      return await schedulersService.getSchedulers(id, level);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

export const sendMessageAction = createAsyncThunk<
  void,
  { scheduleId: string; message: string }
>(
  "schedulers/sendMessageAction",
  async (data, { rejectWithValue, getState }) => {
    try {
      if (!data || !data.message || !data.scheduleId)
        throw new Error("Oops, something went wrong");
      const state = getState() as RootState;
      const update = {
        message: data.message,
        userId: state.auth.tutor?.id || state?.auth.user?.id || "",
        fullname: state.auth.tutor?.fullname || state.auth.user?.fullname || "",
      };
      await schedulersService.sendMessage(data.scheduleId, update);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);

export const getHostSchedulesAction = createAsyncThunk<ISchedule[], void>(
  "schedulers/getHostSchedulesAction",
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const tutorId = state.auth.tutor?.id;
      if (!tutorId) throw new Error("Unauthorized");
      return await schedulersService.getHostSchedules(tutorId);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  },
);
