import React from "react";
import { Redirect, Route } from "react-router";
import useGeneral from "../hooks/useGeneral";

interface Props {
  Component: React.FC<any>;
  path: string;
}
const PrivateRoute: React.FC<Props> = ({ Component, ...rest }) => {
  const {
    auth: { user },
  } = useGeneral();
  return (
    <Route
      {...rest}
      exact={true}
      render={(props) => (user ? <Component /> : <Redirect to="/onboarding" />)}
    />
  );
};

export default PrivateRoute;
