// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp, FirebaseApp } from "firebase/app";

// Authentication
import {
  getAuth,
  Auth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

// Firestore
import {
  getFirestore,
  Firestore,
  serverTimestamp,
  increment,
  collection,
  doc,
  onSnapshot,
  setDoc,
  addDoc,
  updateDoc,
  getDoc,
  getDocs,
  where,
  limit,
  query,
  orderBy,
  documentId,
  startAfter,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

// storage
import {
  getStorage,
  FirebaseStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD4sJPleMtdFZQ5tts9kjYum_Grt1XiaKI",
  authDomain: "vernacular-academy.firebaseapp.com",
  projectId: "vernacular-academy",
  storageBucket: "vernacular-academy.appspot.com",
  messagingSenderId: "114351364786",
  appId: "1:114351364786:web:4b88d9e9a68d270ed407ab",
  measurementId: "G-RDDJ8EX9YZ",
};

// Initialize Firebase
let app: FirebaseApp;

if (getApps().length === 0) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

// setUp auth
const auth: Auth = getAuth(app);
// const auth: Auth = initializeAuth(app, { errorMap: debugErrorMap });

auth.useDeviceLanguage();

// Set up firestore
const db: Firestore = getFirestore(app);

// Set up storage
const storage: FirebaseStorage = getStorage(app);

// timestamp
const timestamp = serverTimestamp;

export {
  auth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  db,
  collection,
  doc,
  onSnapshot,
  setDoc,
  addDoc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  orderBy,
  documentId,
  startAfter,
  arrayRemove,
  arrayUnion,
  storage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
  timestamp,
  increment,
  uploadBytesResumable,
};
