import { useIonLoading, useIonToast } from "@ionic/react";
import moment from "moment";
import { useEffect } from "react";
import Auth from "../classes/Auth";
import User from "../classes/User";
import {
  getLoaderOptions,
  getPhoto,
  getToastOptions,
  handleError,
} from "../lib";
import { IUser, setAuth } from "../store/slices/authSlice";
import useGeneral from "./useGeneral";

const useProfile = (userData: IUser) => {
  const [presentToast] = useIonToast();
  const [presentLoader, dismissLoader] = useIonLoading();
  const { dispatch } = useGeneral();
  const user = new User(userData.id);
  const auth = new Auth();

  useEffect(() => {
    let subscription: any;
    user.watchProfile((profile: any, unsub: any) => {
      if (profile?.createdAt) {
        profile = {
          ...profile,
          createdAt: moment(profile?.createdAt.toDate()).fromNow(),
        };
      }
      dispatch(setAuth(profile));
      subscription = unsub;
    });

    return () => {
      if (subscription) {
        subscription();
      }
    };
  }, []);

  const changePassword = async () => {
    presentLoader(getLoaderOptions());
    try {
      await auth.resetPassword(userData?.email);
      dismissLoader();
      presentToast(
        getToastOptions(
          `A password reset email has been sent to ${userData.email}`,
        ),
      );
    } catch (error) {
      dismissLoader();
      presentToast(getToastOptions(handleError(error)));
    }
  };

  // change profile pix
  const changeProfilePics = async () => {
    try {
      const image = await getPhoto();
      presentLoader(getLoaderOptions());
      await user.changeProfilePicture(image.dataUrl!);
      dismissLoader();
      presentToast(getToastOptions("Profile picture updated."));
    } catch (error) {
      dismissLoader();
      presentToast(getToastOptions(handleError(error)));
    }
  };

  return {
    changePassword,
    changeProfilePics,
  };
};

export default useProfile;
