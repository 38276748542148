import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getLoaderOptions,
  getToastOptions,
  handleError,
  getPhoto,
} from "../lib";
// import Auth from "../classes/Auth";
// import User from "../classes/User";
// import { timestamp } from "../firebase.config";
import { useIonLoading, useIonToast } from "@ionic/react";
import { useState } from "react";
import { useAppSelector } from "./useDispatchSelector";
import { ITutor, selectUser } from "../store/slices/authSlice";
import tutorService from "../services/tutor.service";
import { useHistory } from "react-router";

const useTutorRegistration = (tutor?: ITutor | null) => {
  const { user } = useAppSelector(selectUser);
  const history = useHistory();
  const [presentLoader, dismissLoader] = useIonLoading();
  const [tutorPicture, setTutorPicture] = useState<string | undefined>("");
  const [verificationId, setVerificationId] = useState<string | undefined>("");
  const [certification, setCertification] = useState<string | undefined>("");
  const [present] = useIonToast();
  const validationSchema = Yup.object({
    fullname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    gender: Yup.string().required("Required"),
    dob: Yup.date().required("Required"),
    nationality: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    profficientLanguage: Yup.string().required("Required"),
    otherLanguages: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      fullname: user?.fullname || "",
      email: user?.email || "",
      gender: user?.gender || "Male",
      dob: user?.dob || "",
      nationality: user?.nationality || "Nigeria",
      phone: tutor?.phone || "",
      profficientLanguage: tutor?.profficientLanguage || "",
      otherLanguages: tutor?.otherLanguages || "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (
      {
        fullname,
        dob,
        email,
        phone,
        nationality,
        gender,
        profficientLanguage,
        otherLanguages,
      },
      { setSubmitting },
    ) => {
      await presentLoader(getLoaderOptions());
      try {
        if (!tutor && (!tutorPicture || !verificationId || !certification)) {
          dismissLoader();
          return present(getToastOptions("All images are required."));
        }
        // TODO: update tutor profile

        const promise = [];

        if (tutorPicture) {
          promise.push(
            tutorService.uploadFile(
              "profilePicture",
              tutorPicture,
              `tutors/${user?.id!}`,
              {
                customMetadata: {
                  owner: user?.id,
                },
              },
            ),
          );
        }

        if (verificationId) {
          promise.push(
            tutorService.uploadFile(
              "verificationId",
              verificationId,
              `verification/${user?.id!}`,
              {
                customMetadata: {
                  owner: user?.id,
                },
              },
            ),
          );
        }

        if (certification) {
          promise.push(
            tutorService.uploadFile(
              "certification",
              certification,
              `certification/${user?.id!}`,
              {
                customMetadata: {
                  owner: user?.id,
                },
              },
            ),
          );
        }

        const data: any = {
          fullname,
          dob,
          email,
          phone,
          nationality,
          gender,
          profficientLanguage,
          otherLanguages,
        };

        if (promise.length) {
          const res = await Promise.all(promise);
          res.forEach((r) => {
            data[r.name] = r;
          });
        }

        if (tutor) {
          await tutorService.update(user?.id!, data);
        } else {
          await tutorService.create(user?.id!, data);
        }
        dismissLoader();
        present(getToastOptions(`Profile ${tutor ? "Updated" : "created"}.`));

        if (!tutor) {
          history.replace("/tutor-dashboard");
        }
      } catch (error: any) {
        dismissLoader();
        present(getToastOptions(handleError(error)));
      }
    },
  });

  // change tutor pic
  const changeImage = async (setImage: (url: string | undefined) => void) => {
    try {
      presentLoader(getLoaderOptions());
      const imageURL = (await getPhoto()).dataUrl;
      setImage(imageURL);
      dismissLoader();
    } catch (error) {
      dismissLoader();
      present(getToastOptions(handleError(error)));
    }
  };

  return {
    tutorPicture,
    changeImage,
    setTutorPicture,
    formik,
    verificationId,
    setVerificationId,
    certification,
    setCertification,
  };
};

export default useTutorRegistration;
