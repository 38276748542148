import {
  db,
  onSnapshot,
  collection,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  getDoc,
} from "../firebase.config";

class Database {
  private db = db;

  protected getDocument: (
    collection: string,
    document: string,
  ) => Promise<any> = async (collection, document) => {
    if (!collection || !doc) throw new Error("Missing Argument");

    const docSnap = await getDoc(doc(this.db, collection, document));

    if (!docSnap.exists()) return null;

    const data = {
      id: docSnap.id,
      ...docSnap.data(),
    };

    return data;
  };

  protected createDoc: (
    collectionName: string,
    data: any,
    document?: string,
  ) => Promise<void> = async (collectionName, data, document) => {
    if (!collectionName || !data) throw new Error("Missing Argument");

    if (document) {
      await setDoc(doc(this.db, collectionName, document), data);
    } else {
      await addDoc(collection(this.db, collectionName), data);
    }
  };

  // watch documents
  protected watchDoc: (
    collection: string,
    document: string,
    cb: any,
  ) => Promise<void> = async (collection, document, cb) => {
    if (!collection || !doc || !cb) throw new Error("Missing Argument");

    const unSub = onSnapshot(doc(this.db, collection, document), (doc) => {
      if (doc.exists()) {
        const data = {
          id: doc.id,
          ...doc.data(),
        };

        cb(data, unSub);
      } else {
        cb(null, unSub);
      }
    });
  };

  // update a document
  protected updateDocument: (
    collectionName: string,
    document: string,
    data: any,
  ) => Promise<void> = async (collectionName, document, data) => {
    if (!collectionName || !document || !data)
      throw new Error("Missing Argument");
    await updateDoc(doc(this.db, collectionName, document), data);
  };
}

export default Database;
