import { httpClient } from "../config";
import authService from "./auth.service";

const paymentService = {
  create: async (
    subscriptionId: string,
    renewal: boolean,
  ): Promise<{ url: string }> => {
    const token = await authService.getAuthToken();
    const body = renewal
      ? {
          subscriptionId,
          yearly: renewal,
        }
      : {
          subscriptionId,
        };
    const { data } = await httpClient.post("/payments/create", body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  },
};

export default paymentService;
