import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { ILesson } from "../../types";
import { fetchRecommendedAction } from "../actions/recommended.actions";

// Define a type for the slice state
export interface IRecommended {
  recommended: ILesson[];
}

// Define the initial state using that type
const initialState: IRecommended = {
  recommended: [],
};

export const recommendedSlice = createSlice({
  name: "recommended",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchRecommendedAction.fulfilled, (state, action) => {
      state.recommended = action.payload;
    }),
});

export const selecteRecommended = (state: RootState) => state.recommended;

const recommendedReducer = recommendedSlice.reducer;
export default recommendedReducer;
