import { useIonLoading, useIonToast } from "@ionic/react";
import Auth from "../classes/Auth";
import { getLoaderOptions, getToastOptions, handleError } from "../lib";
import { selectUser } from "../store/slices/authSlice";
import { selectSplit } from "../store/slices/splitSlice";
import { useAppDispatch, useAppSelector } from "./useDispatchSelector";

const useGeneral = () => {
  const [presentToast] = useIonToast();
  const [presentLoader, dismissLoader] = useIonLoading();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectUser);
  const split = useAppSelector(selectSplit);

  const signOut = async () => {
    presentLoader(getLoaderOptions());
    try {
      const auth = new Auth();

      await auth.signOut();
      dismissLoader();
    } catch (error) {
      presentToast(getToastOptions(handleError(error)));
      dismissLoader();
    }
  };
  return {
    auth,
    split,
    signOut,
    dispatch,
  };
};

export default useGeneral;
