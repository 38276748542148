import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  eyeOffOutline,
  eyeOutline,
  lockClosedOutline,
  mailOutline,
} from "ionicons/icons";
// import '../styles/Login.css';
import { useState } from "react";
import useLogin from "../hooks/useLogin";

const Login: React.FC = () => {
  const { formik } = useLogin();
  const [pwdVisible, setPwdVisible] = useState(false);
  return (
    <IonRow className="h-full">
      <IonCol
        size="12"
        sizeLg="6"
        offsetLg="3"
        className="mt-auto mb-auto"
      ></IonCol>
      <IonCol size="12" sizeLg="6" offsetLg="3">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <h1>Welcome Back!</h1>
            </IonCardTitle>
            <p>Fill in the following to log into your account</p>
          </IonCardHeader>
          <IonCardContent>
            <form className="mt-40  mb-auto" onSubmit={formik.handleSubmit}>
              <IonRow>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonIcon icon={mailOutline} slot="start" />
                    <IonInput
                      type="email"
                      name="email"
                      placeholder="Enter email address here"
                      value={formik.values.email}
                      onIonChange={formik.handleChange}
                    />
                  </IonItem>
                  {formik.touched.email && formik.errors.email ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">{formik.errors.email}</p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonItem color="clear">
                    <IonLabel position="stacked">Password</IonLabel>
                    <IonIcon icon={lockClosedOutline} slot="start" />
                    <IonInput
                      type={pwdVisible ? "text" : "password"}
                      name="password"
                      placeholder="Enter password here"
                      value={formik.values.password}
                      onIonChange={formik.handleChange}
                    />
                    <IonIcon
                      icon={pwdVisible ? eyeOffOutline : eyeOutline}
                      slot="end"
                      onClick={() => setPwdVisible(!pwdVisible)}
                    />
                  </IonItem>
                  {formik.touched.password && formik.errors.password ? (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                        {formik.errors.password}
                      </p>
                    </IonText>
                  ) : null}
                </IonCol>
                <IonCol size="12">
                  <IonButton
                    expand="block"
                    color="success"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="ion-no-margin"
                  >
                    Log In
                  </IonButton>
                </IonCol>
                <IonCol size="12">
                  <IonItem
                    fill="outline"
                    mode="md"
                    detail={false}
                    lines="none"
                    routerLink="/authentication/reset"
                  >
                    <IonLabel>Forgort Password? </IonLabel>
                    Reset
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonButton
                    routerLink="/authentication/signup"
                    fill="clear"
                    color="medium"
                    expand="block"
                    mode="md"
                  >
                    Don't have an account? Sign Up
                  </IonButton>
                </IonCol>
              </IonRow>
            </form>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default Login;
