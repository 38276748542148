import { CollectionNames } from "../enums";
import {
  collection,
  orderBy,
  limit,
  db,
  query,
  where,
  startAfter,
  getDocs,
} from "../firebase.config";

const transactionsService = {
  fetchTransactions: async (userId: string, lastVisible: any) => {
    let q;
    if (lastVisible) {
      q = query(
        collection(db, CollectionNames.TRANSACTIONS),
        where("userId", "==", userId),
        startAfter(lastVisible),
        orderBy("createdAt", "desc"),
        limit(10),
      );
    } else {
      q = query(
        collection(db, CollectionNames.TRANSACTIONS),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(10),
      );
    }

    const querySnapshots = await getDocs(q);
    const data: any = {
      lastVisible: null,
      transactions: [],
      more: false,
    };

    if (!querySnapshots.empty) {
      if (querySnapshots.docs.length === 10) {
        data.lastVisible = querySnapshots.docs[querySnapshots.docs.length - 1];
        data.more = true;
      }
      data.transactions = querySnapshots.docs.map((doc) => ({
        docId: doc.id,
        ...doc.data(),
      }));
    }

    return data;
  },
};

export default transactionsService;
