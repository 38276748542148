import {
  storage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "../firebase.config";
import { IStorage } from "../types/storage";
class Storage implements IStorage {
  private storage = storage;

  //   upload a file
  uploadFile: (
    dataUrl: string,
    filePath: string,
    metadata?: any,
  ) => Promise<{ url: string; path: string }> = async (
    dataUrl: string,
    filePath: string,
    metadata,
  ) => {
    const imageRef = ref(this.storage, filePath);
    let res;
    if (metadata) {
      res = await uploadString(imageRef, dataUrl, "data_url", metadata);
    } else {
      res = await uploadString(imageRef, dataUrl, "data_url");
    }
    const downloadurl = await getDownloadURL(res.ref);

    const data = {
      url: downloadurl,
      path: res.ref.fullPath,
    };

    return data;
  };

  //   Delete a file
  deleteFile: (filePath: string) => Promise<void> = async (filePath) => {
    const imageRef = ref(this.storage, filePath);
    await deleteObject(imageRef);
  };
}

export default Storage;
