import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import useReset from "../hooks/useReset";
// import '../styles/Reset.css';

const Reset: React.FC = () => {
  const { formik } = useReset();
  return (
    <IonRow className="h-full">
      <IonCol
        size="12"
        sizeLg="6"
        offsetLg="3"
        className="mt-auto mb-auto"
      ></IonCol>
      <IonCol size="12" sizeLg="6" offsetLg="3">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <h1>Reset Password</h1>
            </IonCardTitle>
            <p>Fill in your email, and we would send a password reset link</p>
          </IonCardHeader>
          <IonCardContent>
            <form className="md-mt-40  mb-auto" onSubmit={formik.handleSubmit}>
                <IonRow>
                  <IonCol size="12">
                    <IonItem color="clear">
                      <IonLabel position="stacked">Email</IonLabel>
                      <IonIcon icon={mailOutline} slot="start" />
                      <IonInput
                        type="email"
                        name="email"
                        placeholder="Enter email address here"
                        value={formik.values.email}
                        onIonChange={formik.handleChange}
                      />
                    </IonItem>
                    {formik.touched.email && formik.errors.email ? (
                      <IonText color="danger">
                        <p className="ion-padding-start">
                          {formik.errors.email}
                        </p>
                      </IonText>
                    ) : null}
                  </IonCol>
                  <IonCol size="12">
                    <IonButton
                      expand="block"
                      color="warning"
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="ion-no-margin"
                    >
                      Reset Password
                    </IonButton>
                  </IonCol>
                  <IonCol size="12">
                    <IonItem
                      routerLink="/authentication/login"
                      fill="outline"
                      mode="md"
                      detail={false}
                      lines="none"
                    >
                      <IonLabel>Remembered Password? </IonLabel>
                      Login
                    </IonItem>
                  </IonCol>
                </IonRow>
            </form>
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default Reset;
