import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLanguagesService } from "../../services/languages.service";
import { ILanguage } from "../../types";

export const fetchLanguagesAction = createAsyncThunk<ILanguage[], void>(
  "languages/fetchLanguagesAction",
  async () => {
    const languages = await fetchLanguagesService();
    return languages;
  },
);
