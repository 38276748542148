import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackOutline,
  callOutline,
  globeOutline,
  golfOutline,
  mailOutline,
  maleFemaleOutline,
  personOutline,
} from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import ErrorText from "../components/ErrorText";
import Languages from "../components/LanguagesList";
import Toolbar from "../components/Toolbar";
import useTutorRegistration from "../hooks/useTutorRegistration";
import countries from "../countries.json";

const TutorRegistration: React.FC = () => {
  const {
    formik,
    changeImage,
    setTutorPicture,
    tutorPicture,
    verificationId,
    setVerificationId,
    certification,
    setCertification,
  } = useTutorRegistration();
  const history = useHistory();
  const location = useLocation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Tutor Registration</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Tutor Registration</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonRow>
          <IonCol size="12" sizeLg="6" className="ion-padding">
            <IonCard className="tutoring-card" color="dark">
              <IonCardContent className="ion-text-start">
                <h3 className="ion-text-capitalize">
                  Intrested In
                  <br />
                  <strong className="">Making Money Tutoring?</strong>
                </h3>
                <p>
                  Monetize your skills and years of tutoring experience. Go
                  digital now and join our tutors, to meet your target teaching
                  audience.
                </p>

                <img
                  src={`assets/img/lady.svg`}
                  alt="tutor lady illustration"
                />
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol
            size="12"
            sizeLg="6"
            className="tutor-registration-form-container ion-padding"
          >
            <div className="flex flex-col h-full">
              <div className="mt-auto mb-auto">
                <h1>Tutor Application</h1>
                <p>Let us get you started, fill in the fields below</p>
              </div>
              <form
                className="ion-margin-top mb-auto"
                onSubmit={formik.handleSubmit}
              >
                <IonRow>
                  <IonCol size="12">
                    <IonCard className="ion-no-margin">
                      <img
                        src={tutorPicture || "assets/icon/icon.png"}
                        alt="profile"
                        className={`${
                          tutorPicture ? "object-top" : "object-center"
                        } object-cover h-300 w-full `}
                      />
                      <IonCardContent>
                        <IonButton
                          expand="block"
                          color="primary"
                          onClick={() => changeImage(setTutorPicture)}
                        >
                          Upload Profile Image
                        </IonButton>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">Full name</IonLabel>
                      <IonIcon icon={personOutline} slot="start" />
                      <IonInput
                        type="text"
                        name="fullname"
                        disabled
                        value={formik.values.fullname}
                        onIonChange={formik.handleChange}
                      />
                    </IonItem>
                    <ErrorText
                      touched={formik.touched.fullname}
                      errors={formik.errors.fullname}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">Email</IonLabel>
                      <IonIcon icon={mailOutline} slot="start" />
                      <IonInput
                        type="email"
                        disabled
                        name="email"
                        value={formik.values.email}
                        onIonChange={formik.handleChange}
                      />
                    </IonItem>
                    <ErrorText
                      touched={formik.touched.email}
                      errors={formik.errors.email}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">Phone Number</IonLabel>
                      <IonIcon icon={callOutline} slot="start" />
                      <IonInput
                        type="tel"
                        name="phone"
                        value={formik.values.phone}
                        onIonChange={formik.handleChange}
                      />
                    </IonItem>
                    <ErrorText
                      touched={formik.touched.phone}
                      errors={formik.errors.phone}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">Gender</IonLabel>
                      <IonIcon icon={maleFemaleOutline} slot="start" />

                      <IonSelect
                        name="gender"
                        disabled
                        placeholder="Select Gender"
                        onIonChange={formik.handleChange}
                        value={formik.values.gender}
                      >
                        {["Male", "Female", "Other"].map((v) => (
                          <IonSelectOption value={v} key={v}>
                            {v}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                    <ErrorText
                      touched={formik.touched.gender}
                      errors={formik.errors.gender}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">Nationality</IonLabel>
                      <IonIcon icon={golfOutline} slot="start" />
                      <IonSelect
                        name="nationality"
                        disabled
                        placeholder="Select Country"
                        value={formik.values.nationality}
                        onIonChange={formik.handleChange}
                      >
                        {countries.map((v, i) => (
                          <IonSelectOption value={v} key={i}>
                            {v}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                    {formik.touched.nationality && formik.errors.nationality ? (
                      <IonText color="danger">
                        <p className="ion-padding-start">
                          {formik.errors.nationality}
                        </p>
                      </IonText>
                    ) : null}
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">
                        Proficient Language
                      </IonLabel>
                      <IonIcon icon={globeOutline} slot="start" />

                      <IonSelect
                        name="profficientLanguage"
                        placeholder="Select Profficient Language"
                        onIonChange={formik.handleChange}
                        value={formik.values.profficientLanguage}
                      >
                        {Object.entries(Languages).map((v, i) => (
                          <IonSelectOption value={v[0]} key={i}>
                            {v[1].name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                    <ErrorText
                      touched={formik.touched.profficientLanguage}
                      errors={formik.errors.profficientLanguage}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <IonItem color="clear" mode="md">
                      <IonLabel position="floating">Other Languages</IonLabel>
                      <IonIcon icon={globeOutline} slot="start" />
                      <IonInput
                        type="text"
                        name="otherLanguages"
                        value={formik.values.otherLanguages}
                        onIonChange={formik.handleChange}
                      />
                    </IonItem>
                    <ErrorText
                      touched={formik.touched.otherLanguages}
                      errors={formik.errors.otherLanguages}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <IonCard
                      className="ion-no-margin"
                      color={
                        formik.submitCount > 0 && !verificationId
                          ? "danger"
                          : verificationId
                          ? "success"
                          : ""
                      }
                    >
                      <IonCardContent>
                        <IonButton
                          expand="block"
                          color="light"
                          onClick={() => changeImage(setVerificationId)}
                        >
                          Upload Verification ID
                        </IonButton>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                  <IonCol size="12">
                    <IonCard
                      className="ion-no-margin"
                      color={
                        formik.submitCount > 0 && !certification
                          ? "danger"
                          : certification
                          ? "success"
                          : ""
                      }
                    >
                      <IonCardContent>
                        <IonButton
                          expand="block"
                          color="light"
                          onClick={() => changeImage(setCertification)}
                        >
                          Upload Certification
                        </IonButton>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                  {/* submit button */}
                  <IonCol size="12">
                    <IonButton
                      expand="block"
                      color="success"
                      type="submit"
                      // routerLink="/tutor-dashboard"
                      disabled={formik.isSubmitting}
                    >
                      Submit Application
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
      <Toolbar pageURL={location.pathname} />
    </IonPage>
  );
};

export default TutorRegistration;
