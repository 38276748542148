import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import languagesReducer from "./slices/languagesSlice";
import lessonsReducer from "./slices/lessonsSlice";
import recommendedReducer from "./slices/recommendedSlice";
import splitReducer from "./slices/splitSlice";
import subscriptionsReducer from "./slices/subscriptionSlice";
import transactionsReducer from "./slices/transactionsSlice";
import materialsReducer from "./slices/materials.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    split: splitReducer,
    languages: languagesReducer,
    recommended: recommendedReducer,
    lessons: lessonsReducer,
    subscriptions: subscriptionsReducer,
    transactions: transactionsReducer,
    materials: materialsReducer,
  },
  // devTools: false
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
