import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  alarmOutline,
  alertCircle,
  arrowBackOutline,
  calendar,
  caretForwardCircleOutline,
  closeCircleOutline,
  libraryOutline,
  notificationsOutline,
  playCircleOutline,
  schoolOutline,
  starHalfOutline,
  videocamOutline,
  walletOutline,
} from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import AlertCard from "../components/AlertCard";
import Toolbar from "../components/Toolbar";
import { useAppDispatch, useAppSelector } from "../hooks/useDispatchSelector";
import { selectUser } from "../store/slices/authSlice";
import Schedule from "../components/Schedule";
import moment from "moment";
import Attendees from "../components/Attendees";
import Masonry from "react-smart-masonry";
import { useQuery } from "react-query";
import { getHostSchedulesAction } from "../store/actions/scheduler.actions";
import { handleError } from "../lib";

const TutorSchedule: React.FC = () => {
  const { tutor } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { status, data, error } = useQuery({
    queryKey: ["tutor-schedules"],
    queryFn: async () => await dispatch(getHostSchedulesAction()).unwrap(),
  });

  console.log(error);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Tutor Schdule</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Tutor Schdule</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonRow className="ion-padding">
          <IonCol size="12">
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  <div className="flex items-center justify-start">
                    <div className="link-icon m-0 mr-16">
                      <IonIcon icon={calendar} color="primary" />
                    </div>
                    <h1 className="m-0">&nbsp;&nbsp;Upcoming Lessons</h1>
                  </div>
                </IonCardTitle>
              </IonCardHeader>
              <div className="divider m-0"></div>
              <IonCardContent>
                {data?.length ? (
                  <Masonry
                    breakpoints={{ mobile: 0, tablet: 700, desktop: 1024 }}
                    columns={{ mobile: 1, tablet: 2, desktop: 2 }}
                    gap={16}
                  >
                    {/* Only fetch upcoming lessons events that belong to this tutor */}
                    {data.map((up) => (
                      <IonRow className="upcoming-lesson-card">
                        <IonCol size="12" sizeMd="2">
                          <div className="h-full flex items-center justify-center">
                            <div className="w-full text-center md-after-line">
                              <h3 className="font-bold text-md lg-text-lg">
                                {moment(up.eventDate, "YYYY-MM-DD").format(
                                  "DD",
                                )}
                              </h3>
                              <p className="uppercase font-light lg-text-sm">
                                {moment(up.eventDate, "YYYY-MM-DD").format(
                                  "ddd",
                                )}
                              </p>
                            </div>
                          </div>
                        </IonCol>
                        <IonCol size="12" sizeMd="10" className="p-10">
                          <h3 className="font-bold">{up.lesson.title}</h3>
                          <p className="mt-16">{up.description}</p>
                        </IonCol>
                        <IonCol size="12" className="ion-no-padding">
                          <div className="divider"></div>
                          <IonList>
                            <IonItem>
                              <IonLabel className="ion-text-wrap">
                                <IonText color="primary">
                                  <h3>Host</h3>
                                </IonText>
                                <p>{up.host.name}</p>
                              </IonLabel>
                              <IonChip slot="end" color="medium">
                                Tutor
                                <IonIcon icon={schoolOutline} />
                              </IonChip>
                            </IonItem>
                            <IonItem>
                              <IonLabel className="ion-text-wrap">
                                <IonText color="primary">
                                  <h3>{up.eventDate}</h3>
                                </IonText>
                                <p>
                                  Start Time:{" "}
                                  {moment(up.start, "HH:mm").format("HH:mma")} -
                                  Ends:{" "}
                                  {moment(up.end, "HH:mm").format("HH:mma")}
                                </p>
                              </IonLabel>
                              <IonChip slot="end" color="medium">
                                Duration
                                <IonIcon icon={alarmOutline} />
                              </IonChip>
                            </IonItem>
                            {up?.attendees?.length ? (
                              <Attendees
                                attendees={
                                  up.attendees.length > 10
                                    ? up.attended.slice(10)
                                    : up.attendees
                                }
                              />
                            ) : null}
                          </IonList>
                        </IonCol>
                        <IonCol size="12">
                          <IonList>
                            <IonItem
                              color="danger"
                              detail
                              href={`/tutor-classroom/${up.id}`}
                              target="_blank"
                            >
                              <IonIcon
                                icon={videocamOutline}
                                className="animate__animated  animate__pulse animate__infinite"
                                slot="start"
                              />
                              <IonLabel>Launch Lesson</IonLabel>
                            </IonItem>
                          </IonList>
                        </IonCol>
                      </IonRow>
                    ))}
                  </Masonry>
                ) : null}

                {status === "error" && error ? (
                  <AlertCard
                    title="No"
                    subtitle="Upcoming Lessons!"
                    message={handleError(error)}
                    color="tertiary"
                    noCloseBtn
                    noPadding
                  />
                ) : null}

                {status === "success" && !data.length ? (
                  <AlertCard
                    title="No"
                    subtitle="Upcoming Lessons!"
                    message="Sorry, no upcoming live lessons at the moment, please see recorded lessons tab for previous live sessions."
                    color="tertiary"
                    noCloseBtn
                    noPadding
                  />
                ) : null}

                {status === "loading" ? (
                  <IonSpinner className="w-full" />
                ) : null}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>

      <Toolbar pageURL={location.pathname} />
    </IonPage>
  );
};

export default TutorSchedule;
