import {
    IonButton,
    IonContent,
    IonIcon,
    IonModal,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import {
    closeCircleOutline,
  } from "ionicons/icons";
  import { useEffect, useState } from "react";
  
  const Modal = (props:any) => {
    return (
        <IonModal
          isOpen={props.showModal}
          swipeToClose={true}
          onDidDismiss={() => props.showModal && props.handleModal()}
          title={props?.title}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => props.handleModal()}>
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle>{props.title}</IonTitle>
            </IonToolbar>
          </IonHeader>
  
          <IonContent fullscreen>
            {props.children}
          </IonContent>
        </IonModal>
    );
  };
  
  export default Modal;
  