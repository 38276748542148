import { LocalNotifications } from "@capacitor/local-notifications";
import {
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonCardContent,
  IonRow,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonChip,
  IonAvatar,
  IonButton,
  useIonAlert,
  IonSpinner,
} from "@ionic/react";
import {
  calendar,
  schoolOutline,
  alarmOutline,
  videocamOutline,
} from "ionicons/icons";
import { useQuery } from "react-query";
import React from "react";
import Masonry from "react-smart-masonry";
import AlertCard from "./AlertCard";
import { useAppDispatch } from "../hooks/useDispatchSelector";
import { getSchedulersAction } from "../store/actions/scheduler.actions";
import { handleError } from "../lib";
import Schedule from "./Schedule";

const breakpoints = { mobile: 0, tablet: 700, desktop: 1024 };

interface Props {
  lessonId: string;
  breakpoints: typeof breakpoints;
}

const Schedules: React.FC<Props> = ({ lessonId, breakpoints }) => {
  const dispatch = useAppDispatch();
  const { status, error, data } = useQuery({
    queryKey: ["schedulers"],
    queryFn: async () => await dispatch(getSchedulersAction(lessonId)).unwrap(),
    enabled: lessonId ? true : false,
  });

  return (
    <>
      <IonCol size="0" sizeMd="12"></IonCol>
      <IonCol size="12">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <div className="flex items-center justify-start">
                <div className="link-icon m-0 mr-16">
                  <IonIcon icon={calendar} color="primary" />
                </div>
                <h1 className="m-0">&nbsp;&nbsp;Upcoming Lessons</h1>
              </div>
            </IonCardTitle>
          </IonCardHeader>
          <div className="divider m-0"></div>
          <IonCardContent>
            {data?.length ? (
              <Masonry
                breakpoints={breakpoints}
                columns={{ mobile: 1, tablet: 2, desktop: 2 }}
                gap={16}
              >
                {/* Only fetch upcoming lessons events from collection which have a scheduled date greater than now */}
                {data.map((up) => (
                  <Schedule up={up} key={up.id} />
                ))}
              </Masonry>
            ) : null}
            {status === "error" ? (
              <AlertCard
                title="No"
                subtitle="Opps"
                message={handleError(error)}
                color="tertiary"
                noCloseBtn
                noPadding
              />
            ) : null}

            {status === "success" && !data.length ? (
              <AlertCard
                title="No"
                subtitle="Upcoming Lessons!"
                message="Sorry, no upcoming live lessons at the moment, please see recorded lessons tab for previous live sessions."
                color="tertiary"
                noCloseBtn
                noPadding
              />
            ) : null}

            {status === "loading" ? <IonSpinner className="w-full" /> : null}
          </IonCardContent>
        </IonCard>
      </IonCol>
    </>
  );
};

export default Schedules;
