import { CollectionNames } from "../enums";
import { getDocs, db, collection } from "../firebase.config";
import { formatDate } from "../lib";

export const fetchLanguagesService = async () => {
  const snapshots = await getDocs(collection(db, CollectionNames.LANGUAGES));

  const languages: any = [];

  snapshots.forEach((doc) => {
    const language = {
      id: doc.id,
      ...doc.data(),
    };

    formatDate(language);
    languages.push(language);
  });

  return languages;
};
