import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { SVG404 } from "../components/UIComponents";

const NotFound: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <figure style={{ margin: "auto", textAlign: "center" }}>
            <SVG404 />
            <div>
              <figcaption>
                <p className="ion-margin">The page you looking for doesn't exist!</p>
                <IonButton routerLink="/">Return Home</IonButton>
              </figcaption>
            </div>
          </figure>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
