import React from "react";
import { Route } from "react-router";

interface Props {
  Component: React.FC | any;
  path: string;
  exact?: boolean;
}

const PublicRoute: React.FC<Props> = ({ Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component />} />;
};

export default PublicRoute;
