import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackOutline,
  arrowBackSharp,
  chatbubbleEllipsesOutline,
  colorPaletteOutline,
  easelOutline,
  libraryOutline,
  micOffOutline,
  micOutline,
  peopleCircleOutline,
  recordingOutline,
  shareOutline,
  stopCircleOutline,
  videocamOutline,
} from "ionicons/icons";
import { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";

import useClassroom from "../hooks/useClassroom";
import AlertCard from "../components/AlertCard";
import { classNames, handleError } from "../lib";
import moment from "moment";
import Video from "../components/Video";
import SketchPad from "../components/SketchPad";
import Material from "../components/Material";

const TutorClassroom = memo(() => {
  const history = useHistory();
  const [showChat, setShowChat] = useState(false);
  const [showParticipants, setShowParticipants] = useState(true);
  const [showMain, setShowMain] = useState(1);

  const {
    data,
    error,
    loaded,
    user,
    tutor,
    startClass,
    myVideoRef,
    users,
    muteStream,
    myStreamMuted,
    screenData,
    shareScreen,
    stopRecordScreen,
    stopScreenShare,
    recordScreen,
    screenVideoRef,
    chunks,
    messageForm,
    myStream,
    endClass,
    materialsStatus,
    materialsError,
    materials,
  } = useClassroom();

  useEffect(() => {
    if (showMain == 2)
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 1);
  }, [showMain, showChat]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => history.goBack()}>
              <IonIcon ios={arrowBackOutline} md={arrowBackSharp} />
            </IonButton>
          </IonButtons>
          <IonTitle>Tutor Classroom</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen scrollEvents={true}>
        <IonHeader collapse="condense" className="ion-no-border">
          <IonToolbar>
            <IonTitle size="large">Tutor Classroom</IonTitle>
          </IonToolbar>
        </IonHeader>

        {!loaded ? <IonSpinner className="w-full" /> : null}
        {error ? (
          <AlertCard
            title={`Opps`}
            subtitle="Classroom"
            message={
              handleError(error) === "Class not found"
                ? handleError(error)
                : "Cannot access classroom. Please upgrade your subscription"
            }
            color="tertiary"
            noCloseBtn
          />
        ) : null}

        {data ? (
          data?.status === "Past" ? (
            <AlertCard
              title={`Opps`}
              subtitle={data.lesson.title}
              message={"Classroom has ended. Please watch recording."}
              color="tertiary"
              noCloseBtn
            />
          ) : data.status === "Pending" ? (
            <>
              <AlertCard
                title={`Opps`}
                subtitle={data.lesson.title}
                message={
                  "Class not started yet. The host/tutor has to start the class before you can be let in."
                }
                color="tertiary"
                noCloseBtn
              />
              {user?.role === "tutor" && tutor?.id === data.host.id ? (
                moment(
                  `${data.eventDate} ${data.start}`,
                  "YYYY-MM-DD HH:mm"
                ).diff(moment(), "minute") > 10 ? (
                  <IonText>
                    You will be able to start the class 10min before start time
                  </IonText>
                ) : (
                  <IonButton
                    expand="block"
                    color="light"
                    className="mt-16"
                    onClick={() => startClass.mutate()}
                    disabled={startClass.status === "loading"}
                  >
                    {startClass.isLoading ? "Please wait..." : "Start Class"}
                  </IonButton>
                )
              ) : null}
            </>
          ) : (
            <IonRow className="h-full">
              <IonCol
                size="12"
                sizeLg={showChat ? "9" : "12"}
                className="h-full"
              >
                <IonRow>
                  {/* tab area */}
                  <IonCol size="12">
                    {/* video stream area */}
                    <div
                      className={classNames(
                        showMain === 1 && "w-full h-full",
                        "w-0 h-0 overflow-hidden animate__animated animate__fadeIn"
                      )}
                    >
                      <IonRow>
                        {/* main video area */}
                        <IonCol
                          size="12"
                          sizeLg={showParticipants ? "9" : "12"}
                        >
                          <IonRow>
                            {/* tutor video area */}
                            <IonCol size="12">
                              <div className="tutor-video">
                                <video playsInline ref={myVideoRef} autoPlay />
                              </div>
                            </IonCol>

                            {/* video controls area */}
                            <IonCol
                              size="12"
                              sizeMd="10"
                              className="ion-no-padding mt-8"
                            >
                              <IonRow className="ion-no-padding">
                                {myStream ? (
                                  <IonCol size="auto">
                                    <IonButton
                                      onClick={muteStream}
                                      color="light"
                                    >
                                      <IonIcon
                                        slot="icon-only"
                                        icon={
                                          myStreamMuted
                                            ? micOffOutline
                                            : micOutline
                                        }
                                      ></IonIcon>
                                    </IonButton>
                                  </IonCol>
                                ) : null}

                                {user?.id === data.host.id ? (
                                  <>
                                    {screenData ? (
                                      screenData?.userId === user?.id ? (
                                        <IonCol size="auto">
                                          <IonButton
                                            onClick={stopScreenShare}
                                            color="secondary"
                                          >
                                            <IonIcon
                                              slot="icon-only"
                                              icon={stopCircleOutline}
                                            />
                                          </IonButton>
                                        </IonCol>
                                      ) : null
                                    ) : (
                                      <IonCol size="auto">
                                        <IonButton
                                          onClick={shareScreen}
                                          color="secondary"
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={shareOutline}
                                          />
                                        </IonButton>
                                      </IonCol>
                                    )}

                                    <IonCol size="auto">
                                      <IonButton
                                        onClick={() =>
                                          setShowParticipants(!showParticipants)
                                        }
                                        color={
                                          showParticipants ? "primary" : "light"
                                        }
                                      >
                                        <IonIcon
                                          slot="start"
                                          icon={peopleCircleOutline}
                                        ></IonIcon>
                                        Participants
                                      </IonButton>
                                    </IonCol>
                                  </>
                                ) : null}
                              </IonRow>
                            </IonCol>

                            {user?.id === data.host.id ? (
                              <IonCol
                                size="12"
                                sizeMd="2"
                                className="ion-no-padding mt-8"
                              >
                                <IonButton
                                  color="danger"
                                  expand="block"
                                  onClick={() => endClass.mutate()}
                                  disabled={endClass.status === "loading"}
                                >
                                  {endClass.status === "loading"
                                    ? "..."
                                    : "End Lesson"}
                                </IonButton>
                              </IonCol>
                            ) : null}
                          </IonRow>
                        </IonCol>

                        {/* participants video area */}
                        {showParticipants ? (
                          <IonCol size="12" sizeLg="3">
                            {users.length ? (
                              <IonRow>
                                {users.map((user: any) => (
                                  <IonCol>
                                    {
                                      // eslint-disable-next-line react/jsx-no-undef
                                      <Video user={user} key={user.userId} />
                                    }
                                  </IonCol>
                                ))}
                              </IonRow>
                            ) : (
                              <div className="no-participants">
                                {" "}
                                <span className="animate__animated animate__flash animate__infinite">
                                  Awaiting Participants...
                                </span>
                              </div>
                            )}
                          </IonCol>
                        ) : null}
                      </IonRow>
                    </div>

                    {/* whiteboard area */}
                    <div
                      className={classNames(
                        showMain === 2 && "w-full h-full",
                        "w-0 h-0 overflow-hidden animate__animated animate__fadeIn"
                      )}
                    >
                      <div className="relative  overflow-hidden">
                        <SketchPad />
                      </div>
                    </div>

                    {/* material area */}
                    {showMain === 3 && (
                      <div className="animate__animated animate__fadeIn overflow-hidden">
                        {materialsStatus === "loading" ? (
                          <IonSpinner className="w-full" />
                        ) : null}
                        {materialsStatus === "error" && materialsError ? (
                          <AlertCard
                            title={`Opps`}
                            subtitle="Materials"
                            message={handleError(materialsError)}
                            color="tertiary"
                            noCloseBtn
                          />
                        ) : null}

                        {/* {materialsStatus === "success" && !materials?.length ? (
                          <AlertCard
                            title={`Opps`}
                            subtitle="Materials"
                            message={"No materials"}
                            color="tertiary"
                            noCloseBtn
                          />
                        ) : null} */}

                        {materials?.length ? (
                          <Material materials={materials}/>
                        ) : null}
                      </div>
                    )}
                  </IonCol>

                  {/* tab buttons */}
                  <IonCol size="12" className="classroom-menu-btns">
                    <IonRow>
                      <IonCol size="6" sizeMd="auto">
                        <IonButton
                          expand="block"
                          onClick={() => setShowMain(1)}
                          color={showMain == 1 ? "primary" : "light"}
                        >
                          <IonIcon
                            slot="start"
                            icon={videocamOutline}
                          ></IonIcon>
                          Video stream
                        </IonButton>
                      </IonCol>
                      <IonCol size="6" sizeMd="auto">
                        <IonButton
                          expand="block"
                          onClick={() => setShowMain(2)}
                          color={showMain == 2 ? "primary" : "light"}
                        >
                          <IonIcon slot="start" icon={easelOutline}></IonIcon>
                          Whiteboard
                        </IonButton>
                      </IonCol>
                      <IonCol size="6" sizeMd="auto">
                        <IonButton
                          expand="block"
                          onClick={() => setShowMain(3)}
                          color={showMain == 3 ? "primary" : "light"}
                        >
                          <IonIcon slot="start" icon={libraryOutline}></IonIcon>
                          Material
                        </IonButton>
                      </IonCol>
                      <IonCol size="6" sizeMd="auto">
                        <IonButton
                          expand="block"
                          onClick={() => setShowChat(!showChat)}
                          color={showChat ? "primary" : "light"}
                        >
                          <IonIcon
                            slot="start"
                            icon={chatbubbleEllipsesOutline}
                          ></IonIcon>
                          {showChat ? "Hide Chatbox" : "Open Chatbox"}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>

              {showChat ? (
                <IonCol size="12" sizeLg="3" className="chatbox">
                  <div
                    className="chatbox-overlay"
                    onClick={() => setShowChat(!showChat)}
                  ></div>

                  <form
                    className="chatbox-content animate__animated animate__bounceIn"
                    onSubmit={messageForm.handleSubmit}
                  >
                    <div className="chatbox-msg">
                      {data?.chats
                        ? data?.chats.map((chat, i) => (
                            <div key={i} className={classNames(user?.id == chat.userId ? "chatbox-msg-user" : "chatbox-msg-others", "chatbox-chats")}>
                              <h6>{chat.fullname}</h6>
                              <p>{chat.message}</p>
                              <small>{moment(chat.createdAt).format("HH:mm")}</small>
                            </div>
                          ))
                        : null}
                    </div>
                    <IonFooter>
                      <div className="chatbox-input">
                        <textarea
                          name="message"
                          id=""
                          value={messageForm.values.message}
                          onChange={messageForm.handleChange}
                          className={`w-full ${
                            messageForm.touched.message &&
                            messageForm.errors.message
                              ? "border border-red-500"
                              : ""
                          }`}
                          placeholder="Enter message..."
                          autoFocus
                        ></textarea>
                        <IonButton
                          type="submit"
                          disabled={messageForm.isSubmitting}
                        >
                          <IonIcon
                            slot="start"
                            icon={chatbubbleEllipsesOutline}
                          ></IonIcon>
                          {messageForm.isSubmitting ? "..." : "Send"}
                        </IonButton>
                      </div>
                    </IonFooter>
                  </form>
                </IonCol>
              ) : null}
            </IonRow>
          )
        ) : null}
      </IonContent>
    </IonPage>
  );
});

export default TutorClassroom;
