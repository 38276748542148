import React, { useRef } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonSlides,
  IonSlide,
  IonText,
} from "@ionic/react";
import "../styles/Onboarding.css";
import { Redirect } from "react-router";
import useGeneral from "../hooks/useGeneral";

const Onboarding: React.FC = () => {
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const {
    split: { visible },
  } = useGeneral();

  return visible ? (
    <Redirect to="/authentication/login" />
  ) : (
    <IonPage id="onboarding-page">
      <IonContent className="onboarding">
        <IonSlides
          ref={slideRef}
          options={{
            speed: 400,
            slidesPerView: 1,
            autoplay: { delay: 5000 },
          }}
          style={{ height: "100%" }}
        >
          {[
            {
              img: "learning.svg",
              title: "Standard e-learning",
              body: "Our platform provides world standard e-learning that allows your to learn anywhere, anytime",
            },
            {
              img: "cost.svg",
              title: "Affordable Cost",
              body: "Our platform provides world standard e-learning that allows your to learn anywhere, anytime",
            },
          ].map((v, i) => (
            <IonSlide key={i} className="ion-padding">
              <img
                src="assets/icon/icon-white.svg"
                alt=""
                style={{ height: 50, position: "absolute", right: 16, top: 16 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "space-evenly",
                  height: "100%",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <img
                  src={`assets/img/${v.img}`}
                  alt=""
                  style={{ height: 300, margin: "auto" }}
                />
                <IonText className="ion-margin-top" style={{ color: "white" }}>
                  <h3>{v.title}</h3>
                  <p>{v.body}</p>
                </IonText>
                <div style={{ width: "100%" }}>
                  <IonButton
                    color="light"
                    expand="block"
                    onClick={() => slideRef.current!.slideNext()}
                  >
                    NEXT
                  </IonButton>
                  <br />
                  <IonButton
                    fill="clear"
                    color="light"
                    expand="block"
                    onClick={() => slideRef.current!.slideTo(3)}
                  >
                    SKIP
                  </IonButton>
                </div>
              </div>
            </IonSlide>
          ))}
          <IonSlide className="ion-padding">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "space-evenly",
                height: "100%",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <img
                src={`assets/img/logo.svg`}
                alt=""
                style={{ width: "100%", margin: "auto" }}
              />
              <div style={{ width: "100%", margin: "auto" }}>
                <IonButton
                  color="light"
                  expand="block"
                  routerLink="/authentication/signup"
                >
                  SIGN UP
                </IonButton>
                <br />
                <IonButton
                  fill="outline"
                  color="light"
                  expand="block"
                  routerLink="/authentication/login"
                >
                  LOG IN
                </IonButton>
              </div>
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default Onboarding;
